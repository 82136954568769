const Twilio = require('@twilio/voice-sdk');
import api from '../assets/api.js';
import { mapGetters } from 'vuex';
import { EventBus } from '../EventBus.js';

let twilioDevice = null;
let twilioCall = null;
let callInterval = null;
let twilioDevice2 = null;
let twilioCall2 = null;
let callInterval2 = null;

const TwilioDevice = {
	install (Vue) {       
		Vue.mixin({
            data() {
                return {
                    twilioCallFrom:null,
                    twilioCall2From:null
                }
            },
            computed:{
                ...mapGetters({
                    callNotification: "charmCallStore/notification",
                    twilioCallDetailsToUnhold: "charmCallStore/twilioCallDetailsToUnhold",
                    isNotificationActive: 'charmCallStore/isNotificationActive',
                    isCharmCallToastOpen:'charmCallStore/isCharmCallToastOpen',
                    outgoingPhoneNumber: 'charmCallStore/outgoingPhoneNumber',
                    hasPermission: 'authenticationStore/rolePermission',
                    loggedInUser: 'authenticationStore/getUserData',
                    callCount: 'charmCallStore/callCount',
                })
            },
			methods:{
                connectClientWithUsername(){
                    this.fetchAccessToken(this.loggedInUser.id, this.connectTwilioDevice);
                },
                fetchAccessToken(username, handler) {
                    api.post(this, api.CHARM_GENERATE_TOKEN, {userId : username}).then(r => {
                        if(!r.token) return;
                        handler(r.token);
                    })
                    .catch(function (error) {
                        console.log('Error in generate token api:', error);
                    });
                },
                async connectTwilioDevice(token) {   
                    twilioDevice = new Twilio.Device(token, {
                        codecPreferences: ["opus", "pcmu"],
                        allowIncomingWhileBusy: false,
                        disableAudioContextSounds: false,
                        maxCallSignalingTimeoutMs: 30000,
                        logLevel: 3,
                    });
                    let ids=[]

                    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                        const constraints = {
                            video: false,
                            audio: true 
                        };
                    
                        // Call getUserMedia, which returns a promise
                      await  navigator.mediaDevices.getUserMedia(constraints)
                            .then(async function() {                                
                                await navigator.mediaDevices.enumerateDevices()
                                    .then(function(devices) {
                                        devices.forEach(function(device) {
                                            console.log(device.kind + ": " + device.label + " (deviceId: " + device.deviceId + ")");
                                            if(device.kind=='audiooutput'){
                                                ids.push(device.deviceId)
                                            }
                                            
                                        });
                                    })
                                    .catch(function(err) {
                                        console.error('Error enumerating devices:', err);
                                    });
                            })
                            .catch(function(err) {
                                // Failed to obtain media stream
                                console.error('getUserMedia error:', err);
                            });
                    } else {
                        // getUserMedia is not supported
                        console.error('getUserMedia is not supported');
                    }
      
                    var speakerDevices = twilioDevice.audio.ringtoneDevices.get();
					console.log('Speaker Devices: before', speakerDevices);
					twilioDevice.audio.ringtoneDevices.set(ids);
                    var speakerDevices2 = twilioDevice.audio.ringtoneDevices.get();
					console.log('Speaker Devices: after', speakerDevices2);
                    this.bindTwilioDeviceEvent();

                    twilioDevice2 = new Twilio.Device(token, {
                        codecPreferences: ["opus", "pcmu"],
                        allowIncomingWhileBusy: false,
                        disableAudioContextSounds: false,
                        maxCallSignalingTimeoutMs: 30000,
                        logLevel: 3,
                        sounds: {
                            // Set false url - no ringtone
                            incoming: 'https://sdk.twilio.com/js/client/sounds'
                        },
                    });
                    this.bindTwilioDevice2Event();
                },

                bindTwilioDeviceEvent(){

                    if(!twilioDevice) return;

                    twilioDevice.register();

                    twilioDevice.on("registered", () => {
                        console.log("Twilio Device registered!");
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    });
                    
                    twilioDevice.on("incoming", call => { 
                        console.log('Twilio incoming call event:', call.parameters.From, call)
                        twilioCall = call;
                        this.twilioCallFrom = call.parameters.From;
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);            
                        twilioCall.on("disconnect", (conn) => {
                            twilioCall = '';
                            this.twilioCallFrom = null;
                            console.log('Twilio disconnect event:', conn.parameters.From)
                            this.$store.dispatch('charmCallStore/endCall', conn.parameters.From);
                            if (!this.isCharmCallToastOpen && this.isNotificationActive) {
                                this.$store.dispatch('charmCallStore/showCharmCallToast', true);
                            }
                            clearInterval(callInterval);
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        });
                        twilioCall.on("cancel", () => {
                            this.$store.dispatch('charmCallStore/handleDismissIncomingCall2',twilioCall);
                            twilioCall = '';
                            this.twilioCallFrom = null;
                            clearInterval(callInterval);
                            console.log('Twilio cancel event')
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        });
                    }); 

                    twilioDevice.on('tokenWillExpire', () => {
                        twilioCall = ''
                        this.twilioCallFrom = null;
                        // If route path is Not Login, Logout, 404 and having 'mini-charm' permission then only refreshToken.
                        if (this.$route && !['/login', '/logout', '/404'].includes(this.$route.path) && this.hasPermission('mini_charm')) {
                            this.refreshToken();
                        }
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    });

                    twilioDevice.on("unregistered", () => {   
                        twilioCall = '';
                        this.twilioCallFrom = null;
                        console.log("Twilio Device unregistered!");
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    }); 

                    twilioDevice.on("error", () => {
                        twilioCall = '';
                        this.twilioCallFrom = null;
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    }); 

                    twilioDevice.on("destroyed", () => {   
                        twilioCall = '';
                        this.twilioCallFrom = null;
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    });
                },    
                bindTwilioDevice2Event(){
                    if(!twilioDevice2) return;
                    
                    twilioDevice2.register();

                    twilioDevice2.on("registered", () => {
                        console.log("Twilio Device2 registered..!");
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    });
                    
                    twilioDevice2.on("incoming", call => { 
                        console.log('Twilio2 incoming call event:', call.parameters.From, call)
                        twilioCall2 = call;
                        this.twilioCall2From =  call.parameters.From;
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        twilioCall2.on("disconnect", (conn) => {
                            twilioCall2 = '';
                            this.twilioCall2From = null;
                            console.log('Twilio2 disconnect event:', conn.parameters.From)
                            this.$store.dispatch('charmCallStore/endCall', conn.parameters.From);
                            if (!this.isCharmCallToastOpen && this.isNotificationActive) {                                
                                this.$store.dispatch('charmCallStore/updateagentCallSid', call.parameters.CallSid);
                                this.$store.dispatch('charmCallStore/showCharmCallToast', true);
                            }
                            clearInterval(callInterval2);
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        });
                        twilioCall2.on("cancel", () => {
                            this.$store.dispatch('charmCallStore/handleDismissIncomingCall2',twilioCall2);
                            twilioCall2 = '';
                            this.twilioCall2From = null;
                            clearInterval(callInterval2);
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                            console.log('Twilio2 cancel event.')
                        });
                    }); 

                    twilioDevice2.on("unregistered", () => {   
                        twilioCall2 = '';
                        this.twilioCall2From = null;
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        console.log("Twilio Device2 unregistered!");
                    }); 
                    
                    twilioDevice2.on("error", () => {
                        twilioCall2 = '';
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        this.twilioCall2From = null;
                    }); 

                    twilioDevice2.on("destroyed", () => {   
                        twilioCall2 = '';
                        EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        this.twilioCall2From = null;
                    }); 
                },    

                acceptTwilioCall(data){
                    let caller = '+' + data.phone_number;
                    if (twilioCall?.parameters?.From === caller) {
                        twilioCall.accept();
                        this.acceptCallApi(data);
                        twilioCall.on('accept', () => {
                            this.$store.dispatch('charmCallStore/answerCall');
                            callInterval = setInterval(() => this.$store.dispatch('charmCallStore/startCallDurationTimer'), 1000);
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        })
                    } else if (twilioCall2?.parameters?.From === caller) {
                        twilioCall2.accept();
                        this.acceptCallApi(data);
                        twilioCall2.on('accept', () => {
                            this.$store.dispatch('charmCallStore/answerCall');
                            callInterval2 = setInterval(() => this.$store.dispatch('charmCallStore/startCallDurationTimer'), 1000);
                            EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                        })
                    }
                },

                hangUpTwilioCall(data) {
                    let caller = '+' + data.phone_number;
                    try {
                        if (twilioCall?.parameters?.From === caller && twilioCall2?.parameters?.From === caller) {
                            this.disconnectCall1(data);
                            if (twilioDevice?.isBusy) {
                                this.disconnectCall1(data);
                            } else if (twilioDevice2?.isBusy) {
                                this.disconnectCall2(data);
                            }
                        } else if (twilioCall?.parameters?.From === caller) {
                            this.disconnectCall1(data);
                        } else if (twilioCall2?.parameters?.From === caller) { 
                            this.disconnectCall2(data);
                        } else {
                            this.$store.dispatch('charmCallStore/updateNotificationStatus', 'ended');
                        }
                    } catch (error) {
                        console.log(error)
                    }
                },
                disconnectCall1(data) {
                    this.endCallApi(data);
                    twilioCall.disconnect();
                    if (twilioCall) {
                        let callStatus = twilioCall ? twilioCall.status() : '';
                        if (['open', 'pending'].includes(callStatus)) {
                            twilioCall?.reject();
                            this.$store.dispatch('charmCallStore/updateNotificationStatus', 'ended');
                        }
                    }
                    twilioCall = '';
                    this.twilioCallFrom = null;
                },
                disconnectCall2(data) {
                    this.endCallApi(data);
                    twilioCall2.disconnect();
                    if (twilioCall2) {
                        let callStatus = twilioCall2 ? twilioCall2.status() : '';
                        if (['open', 'pending'].includes(callStatus)) {
                            twilioCall2?.reject();
                            this.$store.dispatch('charmCallStore/updateNotificationStatus', 'ended');
                        }
                    }
                    twilioCall2 = '';
                    this.twilioCall2From = null;
                },
                acceptCallApi(data) {
                    if (data?.twilioDetails?.direction === 'Incoming Call' || this.twilioCallDetailsToUnhold?.data?.direction === 'Incoming Call') {
                        let body = {
                            call_sid: data?.twilioDetails?.call || this.twilioCallDetailsToUnhold?.data?.call || '',
                            contact_id: this.loggedInUser?.id,
                            conf_name: data?.twilioDetails?.conference_name || this.twilioCallDetailsToUnhold?.data?.conference_name || '',
                            agent_id: this.loggedInUser?.id,
                            facility_id: data?.twilioDetails?.facility || this.twilioCallDetailsToUnhold?.data?.facility || '',
                            from: data?.twilioDetails?.from_phone || this.twilioCallDetailsToUnhold?.data?.from_phone,
                            to:  data?.twilioDetails?.to_phone || this.twilioCallDetailsToUnhold?.data?.to_phone || '',
                            via: data?.twilioDetails?.via_phone || this.twilioCallDetailsToUnhold?.data?.via_phone || '',
                            call_count: this.callCount,
                            direction: data?.twilioDetails?.direction || this.twilioCallDetailsToUnhold?.data?.direction || ''
                        }
                        api.put(this, api.CHARM_CALL_ACCEPT, body).then(r => {
                        }).catch(err => {
                            console.log(err);
                        })
                    }
                },

                endCallApi(data) {
                    if (data?.twilioDetails?.direction === 'Incoming Call' || this.twilioCallDetailsToUnhold?.data?.direction === 'Incoming Call') {
                        let body = {
                            call_sid: data?.twilioDetails?.call || this.twilioCallDetailsToUnhold?.data?.call || '',
                            contact_id: this.loggedInUser?.id,
                            conf_name: data?.twilioDetails?.conference_name || this.twilioCallDetailsToUnhold?.data?.conference_name || '',
                            agent_id: this.loggedInUser?.id,
                            facility_id: data?.twilioDetails?.facility || this.twilioCallDetailsToUnhold?.data?.facility || '',
                            from: data?.twilioDetails?.from_phone || this.twilioCallDetailsToUnhold?.data?.from_phone,
                            to:  data?.twilioDetails?.to_phone || this.twilioCallDetailsToUnhold?.data?.to_phone || '',
                            via: data?.twilioDetails?.via_phone || this.twilioCallDetailsToUnhold?.data?.via_phone || '',
                            call_count: this.callCount - 1,
                            direction: data?.twilioDetails?.direction || this.twilioCallDetailsToUnhold?.data?.direction || ''
                        }
                        api.post(this, api.CHARM_END_CALL, body).then(r => {
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                },

                refreshToken(){
                    this.connectClientWithUsername();
                },

                unregisterTwilioDevice() {
                    EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                if(twilioDevice?.state === 'registered'){    

                        if(this.callNotification.status == 'call in'){ 
                            twilioCall.ignore();                 
                        }else{
                            this.hangUpTwilioCall(this.callNotification)
                        }
                        twilioDevice?.unregister();
                        twilioDevice?.destroy();
                        twilioDevice = null;
                    }
                },
                unregisterTwilioDevice2() {
                    EventBus.$emit('updateBrowserIdentifier', !!twilioDevice?.isBusy + !!twilioDevice2?.isBusy);
                    if(twilioDevice2?.state === 'registered'){
                        if(this.callNotification.status == 'call in'){ 
                           twilioCall2.ignore();  
                        }else{
                            this.hangUpTwilioCall(this.callNotification)
                        }
                        
                        twilioDevice2?.unregister();
                        twilioDevice2?.destroy();
                        twilioDevice2 = null;
                    }
                },
			},
            watch: {
                '$route.path'() {
                    if (this.$route && ['/logout', '/404'].includes(this.$route.path) && twilioDevice) {
                        this.unregisterTwilioDevice();
                        this.unregisterTwilioDevice2();
                    }
                }
            }
		})
	}
};

export default TwilioDevice;
