<template>
  <div class="pl-1 pt-5" style="width: 100%">
    <div v-if="editor" style="border:1px solid; border-color: #EBEBEB;">
      <hb-icon
	  	class="mr-3"
		:toggle="editor.isActive('bold')"
        @click="editor.chain().focus().toggleBold().run(); toggleBold()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
	  	mdi-format-bold
      </hb-icon>
      <hb-icon
	  class="mr-3"
	  	:toggle="editor.isActive('italic')"
        @click="editor.chain().focus().toggleItalic().run(), toggleitalics()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        mdi-format-italic
      </hb-icon>
      <hb-icon
	  class="mr-3"
	  :toggle="editor.isActive('strike')"
        @click="editor.chain().focus().toggleStrike().run(), toggleStrike()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        mdi-format-strikethrough-variant
      </hb-icon>
      <hb-icon 
	  @click="editor.chain().focus().toggleUnderline().run(),  toggleUnderline()" 
	  class="mr-3"
	  :toggle="editor.isActive('underline')"
	  :class="{ 'is-active': editor.isActive('underline') }">
      mdi-format-underline
    </hb-icon>
    <hb-icon
	  	class="mr-3"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        mdi-format-list-bulleted
    </hb-icon>
	<hb-icon  @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
      mdi-format-align-left
    </hb-icon>
    <hb-icon  @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
      mdi-format-align-center
    </hb-icon>
    <hb-icon  @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
      mdi-format-align-right
    </hb-icon>
    <hb-icon   @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
      mdi-link
    </hb-icon>
	</div>
  <div style="border:1px solid; border-color: #EBEBEB;width: 100%">
    <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import Blockquote from "@tiptap/extension-blockquote"
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import { EventBus } from "../../EventBus.js";

export default {
  name: "RichTextEditor",
  data() {
    return {
      editor: null,
    };
  },
  components: {
    "editor-content": EditorContent,
  },

  props: ["value", "proseHeight", "readonly"],
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
    content: this.value || " ",
    editable: !this.readonly,
		editorProps: {
    attributes: {
      style: `height: ${this.proseHeight}; padding: 30px; overflow-y: scroll;`,
    },
  },
      extensions: [StarterKit, Underline, Blockquote.configure({
        HTMLAttributes: {
          class: 'hb-side-line',
        },
      }),
	  Link.configure({
          openOnClick: true,
          autolink: false,
          linkOnPaste: false,
          validate: href => /^https?:\/\//.test(href),
        }),
	  Placeholder.configure({
          placeholder: 'Compose your Personal Message...',
          
        }),
		TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
		
		],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
    EventBus.$on("HB:templatedata", this.setContentOnCursor);
  },
  destroyed() {
    EventBus.$off("HB:templatedata", this.setContentOnCursor);
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    setContentOnCursor(mytext){
      this.editor.chain().focus().insertContent(mytext).run()
    },
	  setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      let url = window.prompt('URL', previousUrl);

	  if (url.includes("https://") === false && url.includes("http://") === false) {
        url = "https://" + url
    	}

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
  }
};
</script>

<style>

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p {
  font-size: 14px;
  color: #000000;
}

.ProseMirror ul {
  list-style-type: disc !important;
}

.ProseMirror {
	height: var(--proseHeight);
}

.hb-side-line{
  margin:0px 0px 0px 0.8ex;
  border-left:1px solid rgb(204,204,204);
  padding-left:1rem;
}
/* removed unwanted margin in editor */
.tiptap p {
  margin-bottom: 0 !important;
}
</style>