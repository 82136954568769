<template>
    <hb-modal v-model="dialog" show-help-link size="large" title="Record Interaction" @close="$emit('close')">
        <template v-slot:content>

    <div class="w-form add-interaction-form">
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <v-layout>
            <template v-slot:actions v-if="countSectionError('interactions')">
                <v-chip pill small outlined color="error">
                    <v-icon small color="error">mdi-alert-circle</v-icon>
                    <strong>{{ countSectionError('interactions') }} Error<span v-show="countSectionError('interactions') > 1">s</span></strong></v-chip>
            </template>
        </v-layout>

        <v-container class="py-0">
            <v-row>
                <v-col>
                    <v-select
                        :items="activity_types"
                        item-text="label"
                        item-value="id"
                        label="How did they contact you?"
                        v-model="activity_object_id"
                        name="interaction"
                        id="interaction"
                        outlined
                        hide-details
                        :error-messages="errors.collect('interactions.interaction')"
                        data-vv-scope="interactions"
                        v-validate="'required'">
                    </v-select>
                    <span
                        v-show="errors.has('interaction')"
                        class="status-block error-block">
                        Field is required
                    </span>
                </v-col>
            </v-row>

            <v-row class="mt-1">
                <v-col cols="4">
                    <hb-date-picker
                        id="date"
                        name="date"
                        label="Time"
                        v-model="date"
                    ></hb-date-picker>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="8" sm="7">
                    <v-layout row wrap>
                        <v-text-field
                            style="max-width:64px"
                            hide-details
                            v-model="hh"
                            v-validate="'required|numeric|between:1,12'"
                            label="HH"
                            id="HH"
                            data-vv-scope="interactions"
                            data-vv-as="reminder hour"
                            name="hh"
                            :error-messages="errors.collect('interactions.hh')"
                            outlined
                        >
                        </v-text-field>
                        <span class="title mx-1" style="margin-top:9px;">:</span>
                        <v-text-field
                            style="max-width:64px"
                            hide-details
                            v-model="mm"
                            v-validate="'required|numeric|min_value:0|max_value:59'"
                            label="MM"
                            id="MM"
                            data-vv-scope="interactions"
                            data-vv-as="minutes"
                            name="mm"
                            :error-messages="errors.collect('interactions.mm')"
                            outlined
                        >
                        </v-text-field>
                        <v-select
                            :items="['AM', 'PM']"
                            v-validate="todo.add ? 'required' : '' "
                            style="max-width:84px"
                            class="ml-2"
                            hide-details
                            name="ampm"
                            v-model="ampm"
                            label="AM/PM"
                            data-vv-as="am/pm"
                            data-vv-scope="interactions"
                            outlined>
                        </v-select>
                    </v-layout>
                </v-col>
                <span
                    v-show="errors.has('date') || errors.has('ampm') || errors.has('hh') || errors.has('mm')"
                    class="status-block error-block">
                    Please fix the interaction date and time
                </span>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea
                        v-validate="'required|max:1000'"
                        v-model="content"
                        hide-details
                        name="content"
                        height="125"
                        data-vv-as="interaction details"
                        outlined
                        label="Interaction Details"
                    ></v-textarea>
                    <span v-show="errors.has('content')" class="status-block error-block">{{ errors.first('content') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-0 pb-1">
                    <v-checkbox hide-details dense v-model="todo.add" label="Schedule A Reminder to Follow Up"></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="todo.add" class="mt-1">
                <v-col cols="6">
                    <hb-date-picker
                        id="date2"
                        name="date2"
                        label="Remind Me On"
                        :min="currentDate"
                        v-model="todo.date"
                    ></hb-date-picker>
                </v-col>
                <v-col cols="6">
                    <v-layout row wrap v-if="!remindTime">
                        <v-avatar>
                            <v-icon class="mt-1 ml-1" large color="primary" @click="remindTime = true">
                                mdi-alarm
                            </v-icon>
                        </v-avatar>
                    </v-layout>
                    <v-layout row wrap v-if="remindTime">
                        <v-text-field
                            style="max-width:64px"
                            hide-details
                            v-model="todo.hh"
                            v-validate="todo.add ? 'required|numeric|between:1,12' : '' "
                            label="HH"
                            id="reminder_HH"
                            data-vv-scope="interactions"
                            data-vv-as="reminder hour"
                            name="reminder_hh"
                            :error-messages="errors.collect('interactions.reminder_hh')"
                            outlined
                        >
                        </v-text-field>
                        <span class="title mx-1" style="margin-top:9px;">:</span>
                        <v-text-field
                            style="max-width:64px"
                            hide-details
                            v-model="todo.mm"
                            v-validate="todo.add ? 'required|numeric|min_value:0|max_value:59' : '' "
                            label="MM"
                            id="reminder_MM"
                            data-vv-scope="interactions"
                            data-vv-as="reminder minutes"
                            name="reminder_mm"
                            :error-messages="errors.collect('interactions.reminder_mm')"
                            outlined
                        >
                        </v-text-field>
                        <v-select
                            :items="['AM', 'PM']"
                            v-validate="todo.add ? 'required' : '' "
                            style="max-width:84px"
                            class="ml-2"
                            hide-details
                            name="reminder_ampm"
                            v-model="todo.ampm"
                            label="AM/PM"
                            data-vv-as="reminder am/pm"
                            data-vv-scope="interactions"
                            outlined>
                        </v-select>

                    </v-layout>
                </v-col>
            </v-row>
            <v-row v-if="todo.add">
                <v-col cols="12">
                    <v-textarea
                        v-validate="'max:1000'"
                        hide-details
                        data-vv-as="interaction reminder details"
                        outlined
                        name="todo_notes"
                        label="Notes"
                        v-model="todo.details"
                        height="125"
                    ></v-textarea>
                    <span v-show="errors.has('todo_notes')" class="status-block error-block">{{ errors.first('todo_notes') }}</span>
                </v-col>
            </v-row>
            <v-row v-if="todo.add">
                <v-col>
                    <v-select
                        :items="admins"
                        item-text="name"
                        item-value="id"
                        label="Assign To:"
                        v-model="todo.contact_id"
                        id="admin_id"
                        name="admin_id"
                        outlined
                        hide-details
                        :error-messages="errors.collect('interactions.admin_id')"
                        data-vv-scope="interactions"
                        v-validate="'required'">
                    </v-select>
                    <span
                        v-show="errors.has('admin_id')"
                        class="status-block error-block">
                        Please select a valid person to assign this todo reminder.
                    </span>
                    <div class="info-section" v-show="todo.contact_id === null">
                        <span>This will be shown to everyone until someone completes it.</span>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="saveInteraction">Save Interaction</hb-btn>
            <loader  v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
        </template>
      </hb-modal>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import api from '../../assets/api.js';
    import Autocomplete from '../assets/Autocomplete.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: "AddInteraction",
        data() {
            return {
                loaded:false,
                lead:{
                    id: null
                },
                todo: {
                    add: false,
                    date: null,
                    hh: '',
                    mm: '',
                    ampm: 'AM',
                    details: '',
                    contact_id: '',
                },

                date: null,
                currentDate: '',
                hh: '',
                mm: '',
                ampm: 'AM',
                content: null,
                method: null,
                activity_object_id: '',
                convertSelector: '',
                id: null,
                status: null,
                activity_types: ['Note', 'Phone Call',  'Email', 'Contact Form', 'Walk in'],
                properties: [],
                categories: [],
                remindTime: false
            }
        },
        created(){
            this.id = this.contact_id;
            this.fetchAdmins();
        //    this.getActivityTypes();


            var now = moment();
            this.hh = now.format('hh');
            this.mm = now.format('mm');
            this.ampm = now.format('A');
            this.date = moment().startOf('day').toDate();

            this.todo.hh = '05';
            this.todo.mm = '00';
            this.todo.ampm = 'PM';

            this.todo.date = moment().format('YYYY-MM-DD');
            this.currentDate = moment().format('YYYY-MM-DD');

            this.todo.contact_id = this.getUserData.id;

        },
        components:{
            Loader,
            Status,
            HbDatePicker,
            Dropdown,
            Datepicker,
            Autocomplete
        },
        filters:{

            formatDate(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY');
            }
        },
        computed: {
            ...mapGetters({
                getUserData: 'authenticationStore/getUserData'
            }),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        methods: {
            async fetchAdmins(){
                api.get(this, api.ADMIN).then(r => {
                    this.admins = r.admins.filter(a => a.contact_id !== this.getUserData.id ).map(a => {
                        return {
                            id: a.contact_id,
                            name: a.first + ' ' + a.last
                        }
                    });
                    this.admins.unshift({
                        id: null,
                        name: 'Everyone'
                    })
                    this.admins.unshift({
                        id: this.getUserData.id,
                        name: 'Me'
                    })

                });
            },

            countSectionError(section){
                if(!this.errors || !this.errors.items || !this.errors.items.length) return false;
                return this.errors.items.filter(i => i.scope == section).length;
            },

            closeDropdown(event){
	    	// TODO remove with vuetify
                $(event.target).closest('.dropdown-menu').dropdown('toggle');

            },

            saveInteraction(){

                var hr = this.ampm.toLowerCase() === 'pm'? parseInt(this.hh) + 12: this.hh;
                var date  = moment(this.date).format('YYYY-MM-DD');
                var occurred  = moment().format('YYYY-MM-DD HH:mm');

                var data = {
                    content: this.content,
                    method: this.method,
                    created: occurred,
                    context: this.context
                }



                if(this.todo.add){

                    var hours = this.todo.ampm == 'PM'? +this.todo.hh + 12 : this.todo.hh;
                    var start = moment(moment(this.todo.date).format('YYYY-MM-DD') + ' ' + hours + ":" + this.todo.mm, 'YYYY-MM-DD HH:mm');

                    var todo = {
                        start_date: moment(start).toDate(),
                        end_date: moment(start).toDate(),
                        details: this.todo.details,
                        contact_id: this.todo.contact_id
                    }
                    data.Todo = todo;

                }

                this.validate(this).then(status => {
                    if(!status) return;
                    api.post(this, api.CONTACTS + this.contact_id + '/interaction', data).then(r => {

                        this.clearForm();
                        EventBus.$emit('interaction_saved');
                        this.successSet(this.$options.name, "Interaction has been saved");
                    });
                });
            },
            clearForm(){
                this.todo = {
                    add: false,
                    date: moment().format('YYYY-MM-DD'),
                    hh: '05',
                    mm: '00',
                    ampm: 'PM',
                    details: '',
                    contact_id: this.todo.contact_id
                },
                this.date = moment().format('YYYY-MM-DD');
                this.hh = '08';
                this.mm = '00';
                this.ampm = 'AM';

                //     details: '',
                //     contact_id: ''
                // };
                // var now = moment();
                // this.hh = now.format('hh');
                // this.mm = now.format('mm');
                // this.ampm = now.format('A');
                // this.date = new Date();
                //
                // this.todo.hh = '08';
                // this.todo.mm = '00';
                // this.todo.ampm = 'AM';
                // this.todo.date = now.add(1, 'day').startOf('day').toDate();

                this.content = null;
                this.method = '';
            }

        },
        props:['contact_id', 'context','value'],
        watch:{
            contact_id(contact_id){
                this.id = contact_id;
            }
        }
    }

</script>
