<template>
  <span id="component-assets-send-receipt">
    <div class="pa-4">
      Please enter the email address where you would like the receipt to be sent.
    </div>
    <v-row class="pa-0 ma-0 mt-1 pb-4 px-6">
      <!-- <v-col cols="12" class="pa-0 ma-0 pb-2">
        <v-row class="pa-0 ma-0">
          <v-col md="9" class="pa-0 ma-0">
            <v-text-field
              class="pa-0 ma-0 mr-4"
              hide-details
              v-model="formatPhoneLocal"
              v-validate="'required|min_value:.01'"
              data-vv-name="amount"
              data-vv-as="payment"
              disabled=true
            ></v-text-field>
          </v-col>
          <v-col md="3" class="pa-0 ma-0">
            <hb-btn color="secondary" minWidth="110px" @click="sendText" disabled=true
              >Send Text</hb-btn
            >
          </v-col>
        </v-row>
      </v-col> -->
      <v-col cols="12" class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col md="9" class="pa-0 ma-0 pr-3">
            <hb-text-field
              id="input-assets-send-receipt-email"
              name="email"
              placeholder="Enter Email"
              v-model="receipt.email"
              v-validate="{ required: true, max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
              data-vv-name="email"
              data-vv-as="Email"
              :error="errors.has('email')"
              class="ml-n2"
              style="margin-top: 6px;"
            ></hb-text-field>
          </v-col>
          <v-col md="3" class="pa-0 ma-0">
            <hb-btn
              id="button-assets-send-receipt-send_email"
              color="secondary"
              minWidth="110px"
              :disabled="isLoading($options.name)"
              :loading="isLoading($options.name)"
              @click="sendEmail"
            >
              Send Email
            </hb-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </span>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "SendReceipt",
  mixins: [ notificationMixin ],
  data() {
    return {
      payment: {},
      receipt: {
        phone: null,
        email: null
      }
    };
  },
  props: ["payment_id", "contact"],
  computed: {
    /*
    ...mapGetters({
      payments: "onBoardingStore/payments"
    }),
    */
    /*
    formatPhoneLocal(){
      return this.receipt.phone ? this.$options.filters.formatPhone(this.receipt.phone) : null;
    },
    */
  },
  async created() {
    await this.fetchData();
    this.receipt.email = (this.payment.Contact && this.payment.Contact.email) ? this.payment.Contact.email : '';
    // this.receipt.phone = (this.payment.Contact && this.payment.Contact.Phones && this.payment.Contact.Phones.length) ? this.payment.Contact.Phones[0].phone : '';
  },
  methods: {
    async fetchData() {
      let payment_id = this.payment_id || null;
      if (!payment_id) return;
      let r = await api.get(this, api.PAYMENTS + payment_id + "/receipt");
      this.payment = r.payment;
    },
    async sendEmail(){
      let status = await this.$validator.validateAll();
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      }

      let payment_id = this.payment_id || null;
      if (!payment_id) {
        this.showMessageNotification({ type: 'error', description: 'There is no payment id associated with this payment. Receipt cannot be sent.' });
        return;
      }
      var data = {
          email: this.receipt.email,
          browser_time: this.$options.filters.formatDateTimeCustom(this.payment.created, 'MMM DD, YYYY @ h:mma')
      };

      try {
        let response = await api.post(this, api.PAYMENTS + payment_id + "/email", data);
        if(response && response.msg){
          this.showMessageNotification({ type: 'success', description : response.msg});
          this.$emit('close');
        }
      } catch (err) {
        let r = { error: err };
        EventBus.$emit("paymentReader", r);
      }

    },
  },
};
</script>

<style scoped>

</style>
