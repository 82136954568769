import api from '../assets/api'

export default [
    {
      path: '/phone/:phone_number',
      meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master',
        re_fetch:true
      },
      beforeEnter: async (to, from, next) => {
        const phoneNumber = to.params?.phone_number
        let PhoneNumberCheck = /^\d{10,11}$/
        if (!PhoneNumberCheck.test(phoneNumber)){
          next({
            path: '/404'
         })
        return; 
        }
        let r
        try{
          r = await api.get(this, api.CONTACTS + 'check', {
            id: undefined,
            phone: phoneNumber
          });
        }catch(e){
          console.log(e);
        }
        let contactId = r?.contacts[0]?.id
        let leasesCount = r?.contacts[0]?.Leases?.length
          if(contactId && leasesCount > 0){
            console.log("success");
            next({
              path: `/contacts/${contactId}`
            })
          } else if (leasesCount === 0) {
            next({
              path: '/leads'
            })
          } else {
            next({
              path: '/404'
          })
        }
      }
    }
  ]