<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <span class="hb-btn-wrapper">

        <span v-if="accordion" class="hb-btn-accordion-wrapper">
            <v-hover v-slot:default="{ hover }">
                <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :href="href" :target="target" :loading="loading" height="48" color="#FFFFFF" block :width="width" :min-width="minWidth" :class="[ buttonClass, hover ? hoverClass : '', focused ? 'hb-button-focused' : '' ]" depressed :ripple="false" :disabled="disabled" @click="$emit('click')">
                    <v-icon left v-if="prependIcon" :size="xSmall || small ? '16' : large ? '24' : '20'" color="#101318" :class="xSmall || small ? 'mr-1' : 'mr-2'">{{ prependIcon }}</v-icon>
                    <span :class="[ textSize, textColor ]" class="font-weight-medium hb-btn-accordion-font-size">
                        <slot></slot>
                    </span>
                    <v-spacer></v-spacer>
                    <hb-icon right color="#101318" class="mr-n1">{{ appendIcon ? appendIcon : 'mdi-chevron-right' }}</hb-icon>
                </v-btn>
            </v-hover>
        </span>

        <span v-else-if="icon || type === 'icon'">
            <hb-tooltip :disabled="disableTooltip" v-if="$slots.tooltipHeader && ($slots.tooltipBody || tooltip)">
                <template v-slot:item>
                    <v-hover v-slot:default="{ hover }">
                        <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :width="iconDimensions" :height="iconDimensions" :href="href" :target="target" medium class="hb-v-btn-icon" :ripple="false" :disabled="disabled" icon :class="{'hb-button-icon-hover-opacity' : hover && !hoverBackgroundColor && hoverOpacity, 'hb-button-icon-hover' : hover && !hoverBackgroundColor && !hoverOpacity, 'hb-button-icon-active' : button && !activeBackgroundColor}" @click="buttonClicked" :style="alwaysActive || (hoverBackgroundColor && hover) || (activeBackgroundColor && button) ? 'background-color:' + overwriteDefaultColors : ''">
                            <hb-icon
                                v-if="icon"
                                :hover="hover"
                                :toggle="toggle"
                                :small="small"
                                :large-close="largeClose"
                                :color="color"
                                :hover-color="hoverColor"
                                :active-color="activeColor"
                                :active-state-off="activeStateOff"
                                :mdi-icon="mdiIcon"
                                :mdi-code="mdiCode"
                            >
                                <slot></slot>
                            </hb-icon>
                            <span v-else-if="type === 'icon'">
                                <slot :hover="hover" :toggle="toggle"></slot>
                            </span>
                        </v-btn>
                    </v-hover>
                </template>
                <template v-slot:header>
                    <slot name="tooltipHeader"></slot>
                </template>
                <template v-slot:body>
                    <span v-if="$slots.tooltipBody">
                        <slot name="tooltipBody"></slot>
                    </span>
                    <span v-else-if="tooltip">
                        {{ tooltip }}
                    </span>
                </template>
            </hb-tooltip>
            <hb-tooltip :disabled="disableTooltip" v-else-if="$slots.tooltipHeader && !$slots.tooltipBody && !tooltip">
                <template v-slot:item>
                    <v-hover v-slot:default="{ hover }">
                        <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :width="iconDimensions" :height="iconDimensions" :href="href" :target="target" medium class="hb-v-btn-icon" :ripple="false" :disabled="disabled" icon :class="{'hb-button-icon-hover-opacity' : hover && !hoverBackgroundColor && hoverOpacity, 'hb-button-icon-hover' : hover && !hoverBackgroundColor, 'hb-button-icon-active' : button && !activeBackgroundColor}" @click="buttonClicked" :style="alwaysActive || (hoverBackgroundColor && hover) || (activeBackgroundColor && button) ? 'background-color:' + overwriteDefaultColors : ''">
                            <hb-icon
                                v-if="icon"
                                :hover="hover"
                                :toggle="toggle"
                                :small="small"
                                :large-close="largeClose"
                                :color="color"
                                :hover-color="hoverColor"
                                :active-color="activeColor"
                                :active-state-off="activeStateOff"
                                :mdi-icon="mdiIcon"
                                :mdi-code="mdiCode"
                            >
                                <slot></slot>
                            </hb-icon>
                            <span v-else-if="type === 'icon'">
                                <slot :hover="hover" :toggle="toggle"></slot>
                            </span>
                        </v-btn>
                    </v-hover>
                </template>
                <template v-slot:header>
                    <slot name="tooltipHeader"></slot>
                </template>
            </hb-tooltip>
            <hb-tooltip :disabled="disableTooltip" v-else-if="!$slots.tooltipHeader && ($slots.tooltipBody || tooltip)">
                <template v-slot:item>
                    <v-hover v-slot:default="{ hover }">
                        <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :width="iconDimensions" :height="iconDimensions" :href="href" :target="target" medium class="hb-v-btn-icon" :ripple="false" :disabled="disabled" icon :class="{'hb-button-icon-hover-opacity' : hover && !hoverBackgroundColor && hoverOpacity, 'hb-button-icon-hover' : hover && !hoverBackgroundColor, 'hb-button-icon-active' : button && !activeBackgroundColor}" @click="buttonClicked" :style="alwaysActive || (hoverBackgroundColor && hover) || (activeBackgroundColor && button) ? 'background-color:' + overwriteDefaultColors : ''">
                            <hb-icon
                                v-if="icon"
                                :hover="hover"
                                :toggle="toggle"
                                :small="small"
                                :large-close="largeClose"
                                :color="color"
                                :hover-color="hoverColor"
                                :active-color="activeColor"
                                :active-state-off="activeStateOff"
                                :mdi-icon="mdiIcon"
                                :mdi-code="mdiCode"
                            >
                                <slot></slot>
                            </hb-icon>
                            <span v-else-if="type === 'icon'">
                                <slot :hover="hover" :toggle="toggle"></slot>
                            </span>
                        </v-btn>
                    </v-hover>
                </template>
                <template v-slot:body>
                    <span v-if="$slots.tooltipBody">
                        <slot name="tooltipBody"></slot>
                    </span>
                    <span v-else-if="tooltip">
                        {{ tooltip }}
                    </span>
                </template>
            </hb-tooltip>
            <hb-tooltip disabled v-else>
                <template v-slot:item>
                    <v-hover v-slot:default="{ hover }">
                        <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :width="iconDimensions" :height="iconDimensions" :href="href" :target="target" medium class="hb-v-btn-icon" :ripple="false" :disabled="disabled" icon :class="{'hb-button-icon-hover-opacity' : hover && !hoverBackgroundColor && hoverOpacity, 'hb-button-icon-hover' : hover && !hoverBackgroundColor, 'hb-button-icon-active' : button && !activeBackgroundColor}" @click="buttonClicked" :style="alwaysActive || (hoverBackgroundColor && hover) || (activeBackgroundColor && button) ? 'background-color:' + overwriteDefaultColors : ''">
                            <hb-icon
                                v-if="icon"
                                :hover="hover"
                                :toggle="toggle"
                                :small="small"
                                :large-close="largeClose"
                                :color="color"
                                :hover-color="hoverColor"
                                :active-color="activeColor"
                                :active-state-off="activeStateOff"
                                :mdi-icon="mdiIcon"
                                :mdi-code="mdiCode"
                            >
                                <slot></slot>
                            </hb-icon>
                            <span v-else-if="type === 'icon'">
                                <slot :hover="hover" :toggle="toggle"></slot>
                            </span>
                        </v-btn>
                    </v-hover>
                </template>
            </hb-tooltip>
        </span>

        <span v-else class="hb-btn-not-icon">
            <v-hover v-slot:default="{ hover }">
                <v-btn :name="uniqueButtonNameForQA" :id="id" :to="to" :href="href" :target="target" :x-small="xSmall" :small="small" :loading="loading" :large="large" :block="block" :width="width" :min-width="minWidth" :class="[ buttonClass, hover ? hoverClass : '', xSmall ? 'hb-btn-x-small-wrapper' : '', small ? 'hb-btn-small-wrapper' : '', focused ? 'hb-button-focused' : '' ]" depressed :ripple="false" :disabled="disabled" @click="$emit('click')">
                    <v-icon left v-if="prependIcon" :size="xSmall || small ? '16' : large ? '24' : '20'" :color="color === 'secondary' ? '#637381' : '#FFFFFF'" :class="xSmall || small ? 'mr-1' : 'mr-2'">{{ prependIcon }}</v-icon>
                    <span :class="[ textSize, hover && isGradientButton ? gradientButtonHoverTextColor : textColor ]" class="font-weight-regular">
                        <slot></slot>
                    </span>
                    <v-icon right v-if="appendIcon" :size="xSmall || small ? '16' : large ? '24' : '20'" :color="color === 'secondary' ? '#101318' : '#FFFFFF'" :class="appendIconMorePadding ? 'ml-1' : xSmall || small ? 'ml-0' : 'ml-1'">{{ appendIcon }}</v-icon>
                </v-btn>
            </v-hover>
        </span>

    </span>
</template>
<script type="text/babel">

    import HbIcon from './HbIcon.vue';
    import HbTooltip from './HbTooltip.vue';
    
    export default {
        name: "HbBtn",
        data: function() {
            return {
                toggle: false,
            };
        },
        components:{
            HbIcon,
            HbTooltip
        },
        created(){
            if(this.activeByDefault){
                this.toggle = true;
            }
            if(this.active){
                this.toggle = true;
            }
        },
        computed: {
            uniqueButtonNameForQA(){
                let parent = this.$parent && this.$parent.$options && this.$parent.$options.name ? this.$parent.$options.name : 'NoParent';
                let uniqueSignifier = this.icon || this.type && this.type === 'icon' ? 'HbIcon' : this.buttonClass;
                let buttonText = this.mdiIcon && this.mdiIcon.length ? this.mdiIcon : this.$slots && this.$slots.default && this.$slots.default[0] && this.$slots.default[0].text && this.$slots.default[0].text.length ? this.$slots.default[0].text.trim().split(' ').join('-') : 'NoButtonText';

                return 'QA-' + parent + '-' + uniqueSignifier + '-' + buttonText;
            },
            buttonClass() {
                if(this.accordion){
                    return 'hb-accordion-button';
                }
                else if(this.color === 'primary'){
                    return 'hb-primary-button';
                }
                else if(this.color === 'destructive'){
                    return 'hb-destructive-button';
                }
                else if(this.color === 'secondary'){
                    return 'hb-secondary-button';
                }
                else if(this.color === 'charcoal-gradient'){
                    return 'hb-charcoal-gradient-button';
                }
                else if(this.color === 'teal-gradient'){
                    return 'hb-teal-gradient-button';
                }
                else if(this.color === 'white-gradient'){
                    return 'hb-white-gradient-button';
                }
                else {
                    return 'hb-primary-button';
                }
            },
            hoverClass() {
                if(this.accordion){
                    return 'hb-accordion-button-hover';
                }
                else if(this.color === 'primary'){
                    return 'hb-primary-button-hover';
                }
                else if(this.color === 'destructive'){
                    return 'hb-destructive-button-hover';
                }
                else if(this.color === 'secondary'){
                    return 'hb-secondary-button-hover';
                }
                else if(this.color === 'charcoal-gradient'){
                    return 'hb-charcoal-gradient-button-hover';
                }
                else if(this.color === 'teal-gradient'){
                    return 'hb-teal-gradient-button-hover';
                }
                else if(this.color === 'white-gradient'){
                    return 'hb-white-gradient-button-hover';
                }
                else {
                    return '';
                }
            },
            textSize() {
                if(this.xSmall || this.small){
                    return 'hb-button-text-small';
                } 
                else if(this.large) {
                    return 'hb-button-text-large';
                } 
                else {
                    return 'hb-button-text-regular';
                }
            },
            textColor() {
                if(this.color === 'secondary' || this.color === 'white-gradient' || this.accordion){
                    return 'hb-btn-black-text';
                } else {
                    return 'hb-btn-white-text';
                }
            },
            gradientButtonHoverTextColor() {
                if(this.color === 'charcoal-gradient'){
                    return 'hb-btn-black-text';
                }
                else if(this.color === 'teal-gradient'){
                    return 'hb-btn-teal-text';
                }
                else if(this.color === 'white-gradient'){
                    return 'hb-btn-white-text';
                }
                else {
                    return '';
                }
            },
            isGradientButton(){
                if(this.color === 'charcoal-gradient' || this.color === 'teal-gradient' || this.color === 'white-gradient'){
                    return true;
                } else {
                    return false;
                }
            },
            button(){
                if(this.active === true){
                    return true;
                }
                if(this.activeStateOff === true){
                    return false;
                }
                else {
                    return this.toggle;
                }
            },
            overwriteDefaultColors(){
                if(this.activeBackgroundColor && (this.button || this.alwaysActive)){
                    return this.activeBackgroundColor;
                }
                else if(this.hoverBackgroundColor){
                    return this.hoverBackgroundColor;
                }
                else {
                    return '';
                }
            },
            iconDimensions(){
                if(this.largeClose){
                    return '48';
                } else {
                    return this.height;
                }
            }
        },
        methods: {
            buttonClicked(){
                this.toggle = !this.toggle;
                this.$emit('click');
            }
        },
        watch:{
            active(){
                this.toggle = this.active;
            },
        },
        props: {
            type:{
                type: String
            },
            icon: {
                type: Boolean,
                default: false
            },
            color:{
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
            xSmall: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            prependIcon:{
                type: String
            },
            appendIcon:{
                type: String
            },
            tooltip:{
                type: String
            },
            activeByDefault: {
                type: Boolean,
                default: false
            },
            activeStateOff: {
                type: Boolean,
                default: false
            },
            alwaysActive: {
                type: Boolean,
                default: false
            },
            hoverBackgroundColor:{
                type: String
            },
            activeBackgroundColor:{
                type: String
            },
            active:{
                type: Boolean,
                default: false
            },
            block:{
                type: Boolean,
                default: false
            },
            to:{
                type: [String, Object],
                default: undefined
            },
            href:{
                type: String
            },
            target:{
                type: String
            },
            activeColor:{
                type: String
            },
            hoverColor:{
                type: String
            },
            loading:{
                type: Boolean,
                default: false
            },
            minWidth:{
                type: String,
                default: "0"
            },
            height:{
                type: Number,
                default: 40
            },
            id:{
                type: String
            },
            mdiIcon:{
                type: String
            },
            mdiCode:{
                type: String
            },
            disableTooltip:{
                type: Boolean,
                default: false
            },
            largeClose: {
                type: Boolean,
                default: false
            },
            appendIconMorePadding: {
                type: Boolean,
                default: false
            },
            width: {
                type: String
            },
            hoverOpacity: {
                type: Boolean,
                default: false
            },
            focused: {
                type: Boolean,
                default: false
            },
            accordion: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

.hb-btn-black-text {
    color: #101318;
}

.hb-btn-teal-text {
    color: #00848E;
}

.hb-btn-white-text {
    color: #FFFFFF;
}

.v-btn {
  letter-spacing: normal;
}
.v-btn::before {
  background: inherit;
}
.hb-opacity-50 {
  opacity: .5;
}
.hb-button-text-small {
  font-size: 12px;
}
.hb-button-text-regular {
  font-size: 14px;
}
.hb-button-text-large {
  font-size: 16px;
}
.hb-primary-button {
  background: linear-gradient(180deg, #47C0BF -70%, #00848E 126.25%);
  border: 1px solid #00848E;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  color: #FFFFFF;
}
.hb-primary-button-hover {
  background: linear-gradient(180deg, #47C0BF -142.5%, #00848E 103.75%);
  border: 1px solid #005F66;
}
.hb-secondary-button {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}
.hb-secondary-button-hover {
  background: linear-gradient(180deg, #FAFBFC 0%, #EBEFF2 100%);
}
.hb-full-width-button {
  background: #FFFFFF !important;
  border: 1px solid #C4CDD5;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}
.hb-destructive-button {
  background: linear-gradient(180deg, #D34B30 -70%, #C24228 126.25%);
  border: 1px solid #A13621;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  color: #FFFFFF;
}
.hb-destructive-button-hover {
  background: linear-gradient(180deg, #D34B30 -133.75%, #C24228 106.25%);
  border: 1px solid #8C2F1D;
}
.hb-charcoal-gradient-button {
  background: transparent !important;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.hb-charcoal-gradient-button-hover {
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF;
}
.hb-teal-gradient-button {
  background: transparent !important;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.hb-teal-gradient-button-hover {
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF;
}
.hb-white-gradient-button {
  background: transparent !important;
  border: 1px solid #101318;
  color: #101318;
}
.hb-white-gradient-button-hover {
  background: #101318 !important;
  border: 1px solid #101318;
}

.v-tooltip {
  position: relative;
  z-index: 99999;
}
.v-tooltip__content {
  background: #334450 !important;
  opacity: 1 !important;
}

.v-btn.hb-button-icon-hover {
  background: #DFE3E8;
}
.v-btn.hb-button-icon-hover-opacity {
  opacity: .8;
}
.v-btn.hb-button-icon-active {
  background: #E0F5F5;
}
.v-btn > .v-btn__content .v-icon {
  color: #637381;
}
.v-btn.v-btn--disabled svg:not(.svg-outlier) path {
  fill: #cecece;
}
.v-btn.v-btn--disabled svg.svg-outlier path {
  stroke: #cecece;
}
.hb-user-avatar {
  font-size: 18px;
}
.hb-v-btn-icon.v-btn--icon.v-size--default {
  height: 40px;
  width: 40px;
}

.hb-btn-wrapper >>> .v-progress-circular.v-progress-circular--indeterminate {
    height: 20px !important;
    width: 20px !important;
}

.hb-btn-wrapper >>> .hb-btn-x-small-wrapper .v-progress-circular.v-progress-circular--indeterminate {
    height: 10px !important;
    width: 10px !important;
}

.hb-btn-wrapper >>> .hb-btn-small-wrapper .v-progress-circular.v-progress-circular--indeterminate {
    height: 14px !important;
    width: 14px !important;
}

button.hb-button-focused {
    outline: 2px solid #00848E;
}

.hb-accordion-button {
    background: #FFFFFF !important;
    border: 1px solid #DFE3E8 !important;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
}

.hb-accordion-button.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: #FFFFFF !important;
}

.hb-accordion-button-hover {
  /* box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important; */
}

.hb-btn-accordion-font-size {
    font-size: 16px;
}

</style>