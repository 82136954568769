<template>
  <hb-form label="Descriptive Tags" class="descriptive-tags" full required :medium="medium">
    <div style="color: #637381" class="pb-2">
      These tags are for descriptive purposes only. They will have no overall
      effect on the plan you are creating.
    </div>
    <hb-checkbox v-model="storage" :error="isError">
      <template v-slot:label>
        <hb-icon class="pr-2 plan-icon" style="margin-top: -4px">
          mdi-facility-custom-3
        </hb-icon>
        Standard Storage
      </template>
    </hb-checkbox>
    <hb-checkbox v-model="parking" :error="isError">
      <template v-slot:label>
        <hb-icon class="pr-2 plan-icon" style="margin-top: -2px">
          mdi-rv-truck
        </hb-icon>
        Parking
      </template>
    </hb-checkbox>
  </hb-form>
</template>
<script>
export default {
  name: "DescriptiveTagForm",
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    medium: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isError: false,
    }
  },
  computed: {
    parking: {
      get() {
        return this.value?.includes("parking") || false
      },
      set(value) {
        let res = this.storage ? ['storage']: []
        if(value) res.push('parking')
        this.$emit("input", res);
      },  
    },
    storage: {
      get() {
        return this.value?.includes("storage") || false
      },
      set(value) {
        let res = this.parking ? ['parking']: []
        if(value) res.push('storage')
        this.$emit("input", res);
      },
    },
  },
};
</script>

<style lang="scss">	
.descriptive-tags {	
  .hb-aviary-v-checkbox.v-input--selection-controls:not(.v-input--is-label-active)	
    .plan-icon	
    i.v-icon {	
    color: rgb(99, 115, 129) !important;	
  }	
}	
</style>