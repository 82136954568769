<template>
    <div>
        <v-container class="payment-method-container">
            <v-row v-if="!dense_header">
                <v-col>
                    <strong>Card Reader</strong>
                </v-col>
            </v-row>
            <v-row class="px-3 py-5 pt-0">
                <v-select
                    :items="defaultReader"
                    v-model="device"
                    item-value="id"
                    item-text="name"
                    return-object
                    hide-details
                    dense
                    placeholder="Default Reader"
                    @change="setDevice"
                ></v-select>
            </v-row>
            <v-row class="px-3 py-2">
                <v-list-item-avatar tile class="mt-0" style="height: 25px;">
                    <v-img src="/img/payment_method_logos/visa-card.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar tile class="mt-0" style="height: 25px;">
                    <v-img src="/img/payment_method_logos/Mastercard.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar tile class="mt-0 ml-4" style="height: 25px;">
                    <v-img src="/img/payment_method_logos/american-express.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar tile class="mt-n1 ml-4">
                    <v-img src="/img/payment_method_logos/Discover-Card.svg"></v-img>
                </v-list-item-avatar>
                <v-col class="pa-1">
                    <v-checkbox v-if="paymentConfig.SHOW_SAVE_PAYMENT" class="mt-0 pt-0 float-right" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script type="text/babel">
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from '../../../EventBus';

export default {
    name: "TsysReader",
    data: () => ({
        defaultReader: [{
            id: null,
            connection_id: null,
            name: 'Default Reader',
            ip: '',
            port: ''
        }],
        device: {},
        isDropdownDisabled: false,
    }),
    props: ['auto_charge', 'dense_header', 'devices'],
    mounted() {
        if(this.devices && this.devices.length) {
            this.defaultReader = this.devices;
            this.device = this.defaultReader && this.defaultReader.length && this.defaultReader[0];
            this.setDevice();
        }
    },
    computed: {
        ...mapGetters({
          paymentConfig: 'paymentsStore/getPaymentSourceConfig',
          getErrors: 'paymentsStore/getErrors',
          payment_method: 'paymentsStore/getPaymentMethod',
        }),
    },
    created() {
        EventBus.$on('disable_reader_dropdown', ()=>{
            this.isDropdownDisabled = true;
        });
    },
    destroyed() {
      EventBus.$emit("setErrors", []);
      EventBus.$off('HB-Navigation:Search', ()=>{
        this.isDropdownDisabled = false;
      });

    },
    methods: {
        ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
        }),
        setDevice() {
            this.setPaymentMethod({property: 'device_id', propertyValue: this.device.id});
            this.setPaymentMethod({property: 'connection_id', propertyValue: this.device.connection_id});
        },
        updateSaveToAccount(val){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
        }
    },
    watch: {
        auto_charge(){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
        },
    }
};
</script>

<style lang="scss" scoped>
</style>