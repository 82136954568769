<template>
    <span id="component-assets-send-receipt">
      <div class="pa-4">
        Please enter the email address where you would like the invoice to be sent.
      </div>
      <v-row class="pa-0 ma-0 mt-1 pb-4 px-6">
        <!-- <v-col cols="12" class="pa-0 ma-0 pb-2">
          <v-row class="pa-0 ma-0">
            <v-col md="9" class="pa-0 ma-0">
              <v-text-field
                class="pa-0 ma-0 mr-4"
                hide-details
                v-model="formatPhoneLocal"
                v-validate="'required|min_value:.01'"
                data-vv-name="amount"
                data-vv-as="payment"
                disabled=true
              ></v-text-field>
            </v-col>
            <v-col md="3" class="pa-0 ma-0">
              <hb-btn color="secondary" minWidth="110px" @click="sendText" disabled=true
                >Send Text</hb-btn
              >
            </v-col>
          </v-row>
        </v-col> -->
        <v-col cols="12" class="pa-0 ma-0">
          <v-row class="pa-0 ma-0">
            <v-col md="9" class="pa-0 ma-0 pr-3">
              <hb-text-field
                id="input-assets-send-receipt-email"
                name="email"
                placeholder="Enter Email"
                v-model="invoice.email"
                v-validate="{ required: true, max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                data-vv-name="email"
                data-vv-as="Email"
                :error="errors.has('email')"
                class="ml-n2"
                style="margin-top: 6px;"
              ></hb-text-field>
            </v-col>
            <v-col md="3" class="pa-0 ma-0">
              <hb-btn
                id="button-assets-send-receipt-send_email"
                color="secondary"
                minWidth="110px"
                :disabled="isLoading($options.name)"
                :loading="isLoading($options.name)"
                @click="sendEmail"
              >
                Send Email
              </hb-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </span>
  </template>
  
  <script type="text/babel">
  import api from "../../assets/api.js";
  import { mapGetters } from "vuex";
  import { EventBus } from "../../EventBus.js";
  import { notificationMixin } from  '../../mixins/notificationMixin.js';
  
  export default {
    name: "SendInvoice",
    mixins: [ notificationMixin ],
    data() {
      return {
        lease:'',
        attachments:'null',
        payment: {},
        invoice: {
          phone: null,
          email: null
        },
        contact:'',
        property:'',
        invoiceNumber:'',
      };
    },
    props: ["payment_id"],
    computed: {
      /*
      ...mapGetters({
        payments: "onBoardingStore/payments"
      }),
      */
      /*
      formatPhoneLocal(){
        return this.invoice.phone ? this.$options.filters.formatPhone(this.invoice.phone) : null;
      },
      */
    },
    async created() {
      await this.fetchData();
      // this.invoice.email = (this.payment.Contact && this.payment.Contact.email) ? this.payment.Contact.email : '';
      // this.invoice.phone = (this.payment.Contact && this.payment.Contact.Phones && this.payment.Contact.Phones.length) ? this.payment.Contact.Phones[0].phone : '';
    },
    methods: {
      async generateInvoice(invoice) {
      console.log(invoice);
      var data = {
        type: "invoice",
        format: "pdf",
        request_id: invoice,
      };

      let response = await api.post(this, api.REPORTS + "download-pdf", data);
      const arr = new Uint8Array(response.data);
      var blob = new Blob([arr], { type: "application/pdf" });
      var file = new File(
        [blob],
         `Invoice #${this.invoiceNumber}.pdf`,
        { type: blob.type }
      );
      return file;
      },
      async fetchData() {
        let payment_id = this.payment_id || null;
        if (!payment_id) return;
        let r = await api.get(this, api.INVOICES + this.payment_id );
        console.log(r);
        this.invoice.email=r.invoice.Lease.Tenants[0].Contact.email;
        this.contact= r.invoice.Contact;
        this.property= r.invoice.Property;
        this.payment = r.invoice;
        this.invoiceNumber= r.invoice.number
        this.lease= r.invoice.Lease.Unit.number
      },
      async sendEmail(){
        let doc = await this.generateInvoice(this.payment_id);
        this.attachments = doc;
        let status = await this.$validator.validateAll();
        if (!status) {
          this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
          return;
        }
  
        let payment_id = this.payment_id || null;
        if (!payment_id) {
          this.showMessageNotification({ type: 'error', description: 'There is no invoice id associated with this payment. Invoice cannot be sent.' });
          return;
        }
        console.log(this.lease,"this.invoice.Lease.Unit.id");
        var data = {
            contact_id:  this.payment.contact_id,
            message:`<p>Hey ${this.contact.first} ${this.contact.last},</p><p>Hope this finds you well! We've just sent over the invoice you've been waiting for. Please find it attached to this email.</p><p>If you have any questions or need further info, feel free to reach out.</p><p>Thank you for your prompt attention to this matter.</p><p>Best regards,</p><p>${this.property.name}<br>${this.property.Address.address?this.property.Address.address:""}<br>${this.property.Address.city?this.property.Address.city:""}<br>${this.property.Address.region?this.property.Address.region:""}</p><p><br></p>`,
            property_id: this.property.id,
            email: this.invoice.email,
            space: this.lease,
            subject:  `Invoice Attached - Invoice #${this.invoiceNumber}`,
            browser_time: this.$options.filters.formatDateTimeCustom(this.payment.date, 'MMM DD, YYYY @ h:mma'),
            
          };
        // this.attachments=doc
        try {
      
            let File = []
            File.push(this.attachments)
        let body = {
          document_type: this.type,
          document_type_id: this.document_type_id
      }
      let fileUploadResponse = await api.postFile(this,  api.CONTACTS +  this.payment.contact_id + '/upload', body, File);
      data.upload_id = fileUploadResponse.uploads.id
      data.attachments= File
      data.attachments[0].content_type = fileUploadResponse.uploads.mimetype
      // return response
     let response = await api.post(this, api.INVOICES + this.payment_id + "/email", data);
          if(response && response.msg){
            this.showMessageNotification({ type: 'success', description : response.msg});
            this.$emit('close');
          }
        } catch (err) {
          let r = { error: err };
          EventBus.$emit("paymentReader", r);
        }
  
      },
   
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  