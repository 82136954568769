<template>
    <hb-modal size="medium" show-help-link :title="content.title" :footer-off="content.hideFooter" v-model="dialog" @close="_cancel" confirmation>
        <template v-slot:content>
            <div class="py-4 px-6" v-html="content.message"></div>
        </template>
        <template v-slot:right-actions>
            <hb-btn :color="content.buttonType" @click="_confirm" v-if="content.resolver">{{ content.resolver }}</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
    const defaultContent = { title: '', message: '', buttonType: 'primary', resolver: 'Confirm', hideFooter:false }

    export default {
        name: 'DisableRatePlanPopup',
        data() {
            return  {
                dialog: false,
                resolvePromise: undefined,
                rejectPromise: undefined,
                content: defaultContent
            }
        },
        methods: {
            show(opts = {}) {
                this.content = {
                    title: opts.title,
                    message: opts.message,
                    buttonType: opts.buttonType,
                    resolver: opts.resolver,
                    hideFooter: opts.hideFooter
                }
                this.dialog = true
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve
                    this.rejectPromise = reject
                })
            },
            _confirm() {
                this.dialog = false
                this.resolvePromise(true)
            },
            _cancel() {
                this.dialog = false
                this.rejectPromise(new Error("Operation cancelled by user"))
            }
        }
    }
</script>