<template>
  <div>    
    <all-insurances displayRemoveOption :insurances="insurances.future" />
    <all-insurances displayRemoveOption :insurances="insurances.present" />
    <div v-if="!insurances.present.length && !insurances.future.length && declinedInsurance && declinedInsurance.status == 1">
      <span>Tenant provided their own insurance</span>
      <div v-if="declinedInsurance.expirationMonth || declinedInsurance.expirationYear" class="hb-text-night-light hb-font-caption">
        Coverage expires on 
        <span v-if="declinedInsurance.expirationMonth">
          {{ declinedInsurance.expirationMonth - 1 | convertMonthNumberToName }}
        </span>
        <span v-if="declinedInsurance.expirationYear">
          {{ declinedInsurance.expirationYear }}
        </span>
      </div>
    </div>

    <div class="hb-text-light hb-font-caption pt-2 pb-2">
      <hb-tooltip>
        <template slot="item">
          <span style="border-bottom: thin dashed; cursor: pointer">
            Past Insurances ({{ insurances.past && insurances.past.length }})
          </span>
        </template>
        <template slot="body">
          <all-insurances
            :insurances="insurances.past"
            durationPeriodColor="#fff"
            displayNoInsuranceMessage
          />
        </template>
      </hb-tooltip>
    </div>

    <add-new-insurance 
      v-if="showAddInsuranceModal" 
      v-model="showAddInsuranceModal"
      :declinedInsurance="declinedInsurance"
      :currentInsurance="insurances.present && insurances.present[0]" 
      :contact="contact" 
      :lease="lease" 
      @close="closeAddInsuranceModal" />

    <hb-link @click="addInsurance" v-if="!(is_closed || isAuctioned)">Change Coverage</hb-link>
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import AllInsurances from "./AllInsurances.vue";
import AddNewInsurance from "./AddNewInsurance.vue";
import moment from 'moment';
import { EventBus } from '../../../EventBus';

export default {
  name: "InsurancesInfo",
  data() {
    return {
      insurances: {
        past: [],
        present: [],
        future: [],
      },
      declinedInsurance: null,
      showAddInsuranceModal: false
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true
    }
  },

  components: {
    AllInsurances,
		AddNewInsurance
  },

  async created() {
  	EventBus.$on('closeAddInsuranceModal', this.closeAddInsuranceModal);
  	EventBus.$on('fetchInsurances', this.fetchInsurances);

    await this.fetchInsurances();
  },

  destroyed() {
    EventBus.$off('closeAddInsuranceModal', this.closeAddInsuranceModal);
  	EventBus.$off('fetchInsurances', this.fetchInsurances);
  },
  computed: {
    is_closed(){
      return moment(this.lease.end_date).startOf('day') <= moment().startOf('day');
    },
    isAuctioned(){
      return ( ['auction_payment','move_out'].includes(this.lease.auction_status))
    }
  },
  methods: {
    closeAddInsuranceModal() {
      this.showAddInsuranceModal = false;
    },
    addInsurance() {
      this.showAddInsuranceModal = true;
    },
    async fetchInsurances() {
      try {
        let res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/all-insurances`
        );

        let { insurances, declinedInsurance } = res;
        this.insurances = insurances;
        this.declinedInsurance = declinedInsurance
      } catch(err) {
        console.log('err ', err);
      }
    },
  },
};
</script>

<style>
</style>