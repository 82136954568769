<template>
  <hb-card
    class="plan-card"
    :loading="apiLoading"
    :class="{ selected: isSelected }"
  >
    <div class="plan-card-header pt-4 px-6 pr-4">
      <div class="plan-card-top d-flex align-center justify-space-between">
        <slot name="header">
          <div
            class="plan-card-title d-flex align-center"
          >
            <slot name="title-icons"></slot>
            <slot name="title"></slot>
          </div>
          <div
            class="d-flex align-center"
          >
            <slot name="default-icon"></slot>
            <slot name="option"></slot>
          </div>
        </slot>
      </div>
      <div class="plan-card-desc">
        <slot name="description"></slot>
      </div>

      <hb-tabs
        v-model="activeTab"
        :background-color="isSelected ? '#E0F5F5' : 'white'"
      >
        <v-tab v-for="tab in tabs" :key="tab.key" :ripple="false">
          {{ tab.label }}
        </v-tab>
      </hb-tabs>
    </div>

    <v-divider></v-divider>

    <v-tabs-items
      v-model="activeTab"
      class="plan-plan-tabs"
      :style="`background-color: ${isSelected ? '#E0F5F5' : 'white'}`"
    >
      <v-tab-item class="tab-item" v-for="tab in tabs" :key="tab.key">
        <slot :name="tab.key" />
      </v-tab-item>
    </v-tabs-items>
    <slot name="form"></slot>
    <slot name="confirmation"></slot>
  </hb-card>
</template>
<script>
export default {
  name: "PlanCardLayout",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    apiLoading: {
      type: Boolean,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  components: {},
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.plan-card {
  width: 100%;
  .plan-card-header {
    & > div {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .plan-card-desc {
    color: #474f5a;
    font-size: 12px;
  }
  .plan-plan-tabs {
    max-height: 385px;
    border-radius: 4px;
  }

  &.selected {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border: solid 3px #00848e;
      border-radius: 4px;
      position: absolute;
      z-index: 3;
      pointer-events: none;
    }
    background-color: #e0f5f5;
    .hb-form-aviory-label {
      background-color: #83b5b54a;
    }
  }
}
</style>
<style lang="scss">
.plan-card {
  &.selected {
    .hb-aviary-form-label {
      background-color: #83b5b54a;
    }
    .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active):hover,
    .theme--light.v-tabs
      > .v-tabs-bar
      .v-tab:not(.v-tab--active)
      > .v-icon:hover,
    .theme--light.v-tabs
      > .v-tabs-bar
      .v-tab:not(.v-tab--active)
      > .v-btn:hover,
    .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled:hover {
      background-color: #83b5b54a;
    }
  }
  .plan-card-title {
    font-size: 20px !important;
    line-height: 24px;
    font-weight: 500;
    word-break: break-all;
  }
  .plan-card-desc {
    word-break: break-all;
  }
}
</style>