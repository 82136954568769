<template>
  <!-- Base Component for plan cards -->
  <PlanCardLayout ref="cardLayout" class="rent-card" :tabs="tabsData" :apiLoading="apiLoading" :isSelected="isSelected" @click.native="$emit('click')">
    <!-- Card header section -->
    <template slot="title-icons">
      <template v-if="showDescriptiveIcons">
        <DescriptiveTags :plan=descriptiveTagProperties :conf="{ class: 'mr-3' }" />
      </template>
    </template>
    <template slot="title">
      <span :title="rentPlan.name" class="plan-title"> {{ rentPlan.name }} </span>
      <!-- <hb-icon
        v-if="rentPlan.active && isManageable"
        title="Active"
        class="active-icon pl-1"
        color="#00848E"
      >
        mdi-check-decagram
      </hb-icon> -->

    </template>
    <!-- <template v-if="rentPlan.active" slot="default-icon">
      <hb-icon color="#00848E">
        mdi-check-decagram
      </hb-icon>
    </template> -->
    <template v-if="isManageable" slot="option">
      <hb-menu options attach nudge-left="40">
        <v-list>
          <v-list-item
            :ripple="false"
            @click="showRentPlanForm = !showRentPlanForm"
          >
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item :ripple="false" @click="showConfirmationPopup()">
            <v-list-item-title>Set as {{ statusActionText }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item :ripple="false" @click="showConfirmationPopup('delete')">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </hb-menu>
    </template>
    <template v-else-if="isClosable" slot="option">
      <hb-btn @click="close()" active-state-off icon tooltip="Close">
        mdi-close
      </hb-btn>
    </template>
    <template slot="description">
      {{ rentPlan.description }}
    </template>
    <!-- Plan card content section -->
    <template v-for="tab in tabsData">
      <template :slot="tab.key">
        <component
          :is="tab.componentName"
          v-bind="tab.props"
          :key="tab.key"
          @click="$emit('click')"
        />
      </template>
    </template>
    <!-- Plan card settings view -->
    <template slot="plan-settings">
      <div class="plan-settings">
        <hb-form v-if="rentPlan.maximum_raise" label="Maximum Raise" class="rent-plan-last-form">
          <template v-slot:tooltipHeader>
              Maximum Raise
          </template>

          <template v-slot:tooltipBody>
              A maximum raise is the highest amount you can increase the rent, 
              making sure it doesn't go beyond a certain limit.
          </template>
          <span> Rent raise cannot exceed a maximum of {{ getPlanSettingsValue({option: 'max_raise', ...rentPlan.maximum_raise}) }}. </span>
        </hb-form>
        <hb-form v-if="rentPlan.minimum_raise" label="Minimum Raise" class="rent-plan-last-form">
          <template v-slot:tooltipHeader>
              Minimum Raise
          </template>

          <template v-slot:tooltipBody>
              A minimum raise is the lowest amount the rent can go up, 
              ensuring it doesn't drop below a specific level.
          </template>
          <span> Rent raise must be a minimum of {{ getPlanSettingsValue({option: 'min_raise', ...rentPlan.minimum_raise}) }}. </span>
        </hb-form>
        <hb-form label="Rent Cap" class="rent-plan-last-form">
          <template v-slot:tooltipHeader>
              Rent Cap
          </template>

          <template v-slot:tooltipBody>
              A rent cap is a strict upper limit on allowable rent increases, 
              regardless of other constraints, like a maximum raise. In simple terms, a rent cap says, 
              "The rent can't go higher than this amount," no matter what other rules are in place.
          </template>
          <span v-if="rentPlan.rent_cap"> Rent cannot exceed a maximum of {{ getPlanSettingsValue({option: 'rent_cap', ...rentPlan.rent_cap}) }}. </span>
          <span v-else> Property Default. </span>
        </hb-form>
        <hb-form label="Prepay">
          <HbCheckbox
            v-model="rentPlan.prepay_rent_raise"
            readonly
            disabled
            label="Raise rent for people who prepay"
          />
        </hb-form>
      </div>
    </template>
      <!-- Form Modal for Edit rent plan -->
    <template slot="form">
      <RentPlanForm
        ref="form"
        v-model="showRentPlanForm"
        :rent="planData"
        @submit="updatePlan"
      />
    </template>
    <template slot="confirmation">
      <!-- Confirmation Modal for delete rent plan and set as active/inactive -->
      <ConfirmationPopup v-once ref="confirmationPopup" />
    </template>
  </PlanCardLayout>
</template>
<script>
import RentPlanForm from "./RentPlanForm.vue";
import RentIncreaseTimeline from "./RentIncreaseTimeline.vue"
import PlanCardLayout from "../utils/PlanCardLayout.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import { mapState, mapGetters } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { cloneDeep } from "lodash";
import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import DescriptiveTags from "../utils/DescriptiveTags.vue";
export default {
  name: "RentPlanCard",
  components: {
    RentPlanForm,
    PlanCardLayout,
    ConfirmationPopup,
    DescriptiveTags,
    RentIncreaseTimeline
  },
  props: {
    rentPlan: {
      type: Object,
      required: true,
      default: () => {},
    },
    showDescriptiveIcons: {
      type: Boolean,
      default: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    editPermission: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default:'manage'
    },
  },
  mixins: [ notificationMixin ],
  data() {
    return {
      showRentPlanForm: false,
      apiLoading: false,
      loading: false,
      planData: {},

      maximumRaise: false,
      planIcons: [],
      confirmationType: "delete",
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.authenticationStore.company,
    }),
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    confirmationContent() {
      return {
        delete: {
          title: "Delete Rent Plan",
          description: `You are about to delete <strong>${this.rentPlan.name}</strong> Rent Plan.<br/><br/>Are you sure you want to continue?`,
        },
        active: {
          title: `${this.statusActionText} Rent Plan`,
          description: `You are about to set <strong>${this.rentPlan.name}</strong> Plan status as <strong>${this.statusActionText}</strong>.<br/><br/>Are you sure you want to save changes?`,
        },
      };
    },
    statusActionText() {
      return this.rentPlan.active ? "Inactive" : "Active";
    },
    descriptiveTagProperties() {
      return {
        tags: this.rentPlan?.tags ?? [],
        price_delta_type: this.rentValueTypes ?? []
      }
    },
    rentValueTypes() {
      let valueTypes = this.rentPlan.settings?.map((item) => item.value_type)
      return [...new Set(valueTypes)]
    },
    isManageable() {
      return this.editPermission
    },
    tabsData() {
      return [
        {
          label: "Rent Plan",
          key: "rent-plan",
          componentName: "RentIncreaseTimeline",
          props: { stages: this.planData.settings.sort((a,b)=> a.sort_order - b.sort_order) ?? [], isSelected: this.isSelected, isManageable: this.isManageable }

        },
        {
          label: "Plan Settings",
          key: "plan-settings",
          componentName: "",
          props: {  }

        }
      ]
    },
    isClosable() {
      return this.type == 'close'
    },
  },
  watch: {
    rentPlan: {
      immediate: true,
      handler(plan) {
        let error = {
          month: false,
          value: false,
        };
        this.planData = cloneDeep(plan);
        this.planData.settings?.forEach((item) => {
          item["error"] = error;
        });
      },
      deep: true,
    },
    showRentPlanForm(flag) {
      if (!flag) this.$refs.form.resetForm();
    },
  },
  methods: {
    /**
     * Function to trigger all the curresponding action after user confirms the delete or set as active actions
     *
     * If confirmation type is active/inactive, it triggers the API for setting the rent plan as active or inactive
     * If confirmation type is delete, it triggers the API for deleting the rent plan.
     */
    triggerConfirmationAction() {
      this.loading = true;
      if (this.confirmationType == "active")
        this.$emit("setActive", {
          data: this.rentPlan,
          callback: () => {
            this.loading = false;
          },
        });
      else {
        this.$emit("delete", {
          id: this.rentPlan.id,
          callback: () => {
            this.loading = false
          },
        });
      }
    },
    close() {
      this.$refs['cardLayout'].activeTab = 0;
      this.$emit('close');
    },
    getPlanSettingsValue(method) {
      if(['max_raise', 'min_raise', 'rent_cap'].includes(method.option))
        return this.getStructuredPlanSettingsLabels(method)
    },
    getStructuredPlanSettingsLabels(method) {
      let stageLabels = {
				dollar_amount: '$',
				rent_percent: '% of Current Rent',
				rent_sell_variance_percent: '% of Variance (Rent/Sell)',
				sell_rate_percent: '% of Sell Rate',
				set_rate_percent: '% of Set Rate',
			}
      return method?.type === 'dollar_amount' 
          ? `$${method?.value}` 
          : `${method?.value}${stageLabels[method?.type]}`
    },
    /**
     * Function for update rent plan.
     * @param {Object} data payload of the plan
     * @param {Function} callback function to close the button loader
     */
    async updatePlan(data) {
      this.apiLoading = true
      let planData = cloneDeep(data);
      let id = data.id;
      delete planData?.id;
      delete planData?.created_contact_id;
      try {
        await api.put(
          this,
          api.COMPANY_RENT_MANAGEMENT + `plans/${id}/`,
          planData
        );
        this.showMessageNotification({
          type: "success",
          description: `Rent Plan ${data?.name} has been updated.`,
        });
        this.$emit("fetchPlans");
        if (this.selectedProperty) EventBus.$emit("tenantRentManagementEvent", { event: 'refreshTable' })
      } catch (error) {
        console.error("Rent plan updation error", error);
        this.showMessageNotification({
          description: error,
        });
      }
      // callback();
      this.apiLoading = false 
    },


    /**
     * Function to display the confirmation modal.
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showConfirmationPopup(type = "active") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: this.confirmationContent[type].title,
          message: this.confirmationContent[type].description,
          buttonType: type == 'delete' ? 'destructive' : 'primary',
          resolver: `Continue`,
        })
        .catch(() => false);
        if (confirmed) this.triggerConfirmationAction()
    }
  },
};
</script>
<style lang="scss">
.rent-card {
  .plan-settings
  .hb-aviary-v-checkbox.v-input--selection-controls.v-input--hide-details:not(.v-input--is-label-active):hover
  .v-input--selection-controls__input
  > i.v-icon {
    color: #dfe3e8 !important;
  }
  .active-icon i.v-icon {
    font-size: 18px !important;
    margin-bottom: 3px !important;
  }
  .plan-plan-tabs {
    overflow: auto;
  }
  .plan-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-bottom: 2px;
  }
}
</style>
