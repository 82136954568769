<template>
  <div>
    <div color="#101318" class="hb-font-header-2-medium mt-n1">
      Space Information 
    </div>
    <div class="pb-4" v-if="notification">
      <hb-notification v-model="notification" v-if="notification" type="warning" >The following invoices are past due. Please settle the balance before allowing the move-out.</hb-notification>
    </div>

    <!-- CURRENT LEASES -->

    <v-expansion-panels
      v-model="currentPanel"
      multiple
      v-if="leasesArray.length && !isLoading($options.name)"
      :disabled= ivrPaymentSessionStarted
    >
      <hb-expansion-panel
        v-for="(lease, i) in leasesArray"
        v-if="lease.lease_is_active || lease.lease_is_active == null"
        :key="'activeLease_' + i"
        left-cols="7"
        right-cols="5"
      >
        <template v-slot:title>
          <div class="d-flex align-center">
            <span @click.stop v-if="leasesArray.length > 1">
              <hb-checkbox
                v-if="!isAuctionPaymentOrMovedOut(lease)"
                :value="lease.pay"
                @change="updateSelectedLeases(i, 'pay', !leasesArray[i].pay )"
              ></hb-checkbox>
            </span>
            <span v-else-if="lease.Unit" class="pr-1">Space</span>
            <span style="position:relative;top:1px;">
              {{ lease.Unit && lease.Unit.number }}
              <span v-if="lease.PaymentMethods && lease.PaymentMethods.filter((pm) => pm.auto_charge).length">
                <v-icon color="primary" dense class="ml-2 my-0 py-0"
                  >mdi-autorenew</v-icon
                >
              </span>
              <span class="hb-text-light">{{unitAddress(lease.Unit.property_id)}}</span>
              <span v-if="isAuctionPaymentOrMovedOut(lease)" class="ml-1 auction-space">(This space has been auctioned)</span>
            </span>
          </div>
        </template>
        <template v-slot:actions>
          <span class="pr-6 hb-font-header-3-medium">
            <span class="pr-4">Total Due:</span>{{ lease | totalLeaseDue | formatMoney }}
          </span>
        </template>
        <template v-slot:content>
          <v-row justify="center" align="center" v-if="lease.leaseInvoiceLoading && invoicesLoading">
            <v-col class="text-center ma-12">
              <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row no-gutters style="1px solid #dfe3e8; margin-bottom: 0" v-else>
            <v-col cols="12" sm="5" class="form-label pa-4" v-if="paymentConfig.SHOW_AUTOPAY">
              <!-- AutoPay container -->
              <div>
                <div class="font-weight-medium pb-1">
                  Want to help your tenant avoid late fees?
                </div>
                <div>
                  Enroll them in AutoPay to ensure they never miss a payment again.
                </div>
                <div class="pt-4">
                  <v-row>
                    <v-col cols="12" class="pr-0">
                      <hb-checkbox                     
                        :disabled="disableAutopay(lease) || noConnections"
                        :value="lease.auto_charge"
                        @change="setLeaseProperty({ leaseIndex: i, property: 'auto_charge', propertyValue: !leasesArray[i].auto_charge })"
                        label="Enroll in AutoPay"
                      >
                        <template v-slot:tooltipBody>
                            You can only enroll a tenant in <br />
                            AutoPay with a credit or debit <br />
                            card.
                          </template>
                      </hb-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </div>

             
            </v-col>

            <v-col cols="12" :sm="paymentConfig.SHOW_AUTOPAY ? '7' : '12'" class="invoice_display">
              
              <v-row class="invoice-row px-4" v-if="lease.PaymentCycleOptions && lease.PaymentCycleOptions.length && paymentSource !== 'TRANSFER'">
                <v-col>Current Payment Cycle</v-col>
                <v-col class="align-right">
                  <hb-link v-show="paymentSource !== 'TRANSFERS'" class="mr-3" @click="editPaymentCycle(lease, i)">Edit</hb-link>{{lease.payment_cycle || 'Monthly'}}
                </v-col>   
              </v-row>
                 
              <v-row
                v-if="
                  !lease.OpenInvoices ||
                  (!lease.OpenInvoices.length && !lease.AdvanceInvoices.length)
                "
                class="invoice-row mb-4"
              >
                <v-col class="pt-12" style="height: 120px; text-align: center">
                  No open invoices found
                </v-col>
              </v-row>

              <!-- open invoices  -->
              <v-row
                v-for="invoice in lease.OpenInvoices"
                :key="'activeLeaseOpenInvoice_' + invoice.id"
                no-gutters
                class="invoice-row mb-4"
              >
                <v-col>
                  <!-- invoice number -->
                  <v-row v-if="invoice.number" no-gutters class="invoice-line-row">
                    <v-col>
                      <span class="caption">Invoice #{{ invoice.number }}</span>
                    </v-col>
                  </v-row>

                  <!-- invoice lines JUST RENT -->
                  <v-row class="invoice-line-row" v-for="line in invoice.InvoiceLines" :key="'activeLeaseOpenInvoiceRentLine_' + line.id" no-gutters>
                    <template v-if="line.Product.default_type == 'rent'">
                      <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                        <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                      </v-col>
                      <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                      <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                    </template>
                  </v-row>
                  
                  <!-- other invoice lines -->
                  <v-row
                    class="invoice-line-row"
                    v-for="line in invoice.InvoiceLines"
                    :key="'activeLeaseOpenInvoiceLine_' + line.id"
                    no-gutters
                  >
                    <template v-if="line.Product.default_type != 'rent'">
                      <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                        <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                      </v-col>
                      <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                      <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                    </template>
                  </v-row>

                  <!-- subtotal -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                  >
                    <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      ><strong>{{ invoice.sub_total | formatMoney }}</strong></v-col
                    >
                  </v-row>

                  <!-- discounts -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                    v-if="invoice.discounts"
                  >
                    <v-col style="flex: 1 0 50%">Discounts</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      >({{ invoice.discounts | formatMoney }})</v-col
                    >
                  </v-row>

                  <!-- tax -->
                  <v-row class="invoice-line-row" no-gutters>
                    <v-col style="flex: 1 0 50%">Tax</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right">{{
                      invoice.total_tax | formatMoney
                    }}</v-col>
                  </v-row>

                  <!-- previous payments -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                    v-if="invoice.total_payments"
                  >
                    <v-col style="flex: 1 0 50%">Previous Payments</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      >({{ invoice.total_payments | formatMoney }})</v-col
                    >
                  </v-row>

                  <!-- total now -->
                  <v-row class="invoice-line-row" no-gutters>
                    <v-col style="flex: 1 0 50%"
                      ><strong>Total</strong></v-col
                    >
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      ><strong>{{
                        invoice.balance | formatMoney
                      }}</strong></v-col
                    >
                  </v-row>

                  
                </v-col>
              </v-row>

              <!-- advance payments -->
              <v-row
                v-for="(invoice, i) in lease.AdvanceInvoices"
                :key="'activeLeaseAdvanceInvoice_' + i"
                no-gutters
                class="invoice-row mb-4"
              >
                <v-col>
                  <!-- invoice lines -->
                  <v-row
                    class="invoice-line-row"
                    v-for="(line, j) in invoice.InvoiceLines"
                    :key="'activeLeaseAdvanceInvoiceLine_'+ line.id ? line.id : j"
                    no-gutters
                  >
                    <!-- rent invoice line -->
                    <template v-if="line.Product.default_type == 'rent'">
                      <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                        <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} -{{ line.end_date | formatSmallDate }})</span>
                      </v-col>
                      <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                      <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                    </template>

                    <!-- other invoice lines -->
                    <template v-else>
                      <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                        <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} -{{ line.end_date | formatSmallDate }})</span>
                      </v-col>
                      <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                      <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                    </template>
                  </v-row>

                  <!-- subtotal -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                    v-if="invoice.discounts"
                  >
                    <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      ><strong>{{ invoice.sub_total | formatMoney }}</strong></v-col
                    >
                  </v-row>
                  
                  <!-- discounts -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                    v-if="invoice.discounts"
                  >
                    <v-col style="flex: 1 0 50%">Discount</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      >-{{ invoice.discounts | formatMoney }}</v-col
                    >
                  </v-row>

                  <!-- tax -->
                  <v-row class="invoice-line-row" no-gutters>
                    <v-col style="flex: 1 0 50%">Tax</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right">{{
                      invoice.total_tax | formatMoney
                    }}</v-col>
                  </v-row>

                  <!-- previous payments  -->
                  <v-row
                    class="invoice-line-row"
                    no-gutters
                    v-if="invoice.total_payments"
                  >
                    <v-col style="flex: 1 0 50%">Previous Payments</v-col>
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      >({{ invoice.total_payments | formatMoney }})</v-col
                    >
                  </v-row>
                  
                  <!-- total  -->
                  <v-row class="invoice-line-row" no-gutters>
                    <v-col style="flex: 1 0 50%"
                      ><strong>Total</strong></v-col
                    >
                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                    <v-col style="flex: 0 1 0%" class="align-right"
                      ><strong>{{
                        invoice.balance | formatMoney
                      }}</strong></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              
              <!-- total / balance due  -->
              <v-row no-gutters class="invoice-line-row total pt-0 pb-1 px-5">
                <v-col align-self="center" cols="5" v-if="shouldShowAdditionalMonths">
                 
                  <hb-select
                    :clearable="getMinBilledMonths == 0"
                    @click:clear="changeLeaseBilledMonths(0, lease, i)"
                    :items="additionalMonths(lease)"
                    :value="additionalMonthsValue(lease)"
                    @change="changeLeaseBilledMonths($event, lease, i)"
                    placeholder="Add Additional Time"
                    :disabled="isAuctionPaymentOrMovedOut(lease)"
                  ></hb-select>
                </v-col>
                <v-col 
                  align-self="center"
                  :class="shouldShowAdditionalMonths ? 'pl-6' : 'pl-0'" 
                  :cols="shouldShowAdditionalMonths ? 5 : 10"
                >
                  Total Balance Due:
                </v-col>
                <v-col
                  align-self="center"
                  class="align-right font-weight-medium"
                  cols="2"
                >
                  {{ lease | totalLeaseDue | formatMoney }}</v-col
                >
              </v-row>
            
            </v-col>
          </v-row>
        </template>
      </hb-expansion-panel>

    </v-expansion-panels>


     <hb-modal
          v-model="show_edit_payment_cycle"
          size="medium"
          title="Edit Payment Cycle"
          show-help-link
          
          @close="show_edit_payment_cycle = false"
        >
        <template v-slot:content>

          <hb-form label="Payment Cycle">
            
                <HbSelect
                  :items="payment_cycle_options" 
                  v-model="payment_cycle_form.payment_cycle"
                  item-text="label"
                  item-value="label"
                  return-object
                  :clearable="false"
              ></HbSelect>  
            </hb-form>    
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
            <br />     
        </template>

         <template v-slot:right-actions> 
              <hb-btn @click="changeLeasePaymentCycle" color="primary">Save</hb-btn>
          </template>

      </hb-modal>



    <!-- CLOSED LEASES -->

    <v-expansion-panels v-model="openMainPanel" flat class="hb-expansion-panel mt-2" v-if="closedLeaseCount > 0 && paymentSource === 'TAKE_PAYMENT'" :disabled= ivrPaymentSessionStarted>
      <v-expansion-panel class="hb-cloud-lighter hb-closed-lease-background hb-closed-lease-panel">
        <v-expansion-panel-header
          class="hb-default-font-size ma-0 py-0 px-4 hb-closed-lease-dropdown-header" color="#F9FAFB">
          <span class="hb-text-light text-subtitle-2">CLOSED LEASES ({{closedLeaseCount}})</span>
        </v-expansion-panel-header>
        <v-divider class="my-0 pa-0 mx-3"></v-divider>
        <v-expansion-panel-content class="pa-0 ma-0">
          <v-expansion-panels
            v-model="closedPanel"
            mulitple
            v-if="leasesArray.length"
          >
            <hb-expansion-panel
              v-for="(lease, i) in leasesArray"
              v-if="!lease.lease_is_active && lease.balance > 0"
              :key="'closedLease_' + i"
              left-cols="7"
              right-cols="5"
            >
              <template v-slot:title>
                <div class="d-flex align-center">
                  <span @click.stop v-if="leasesArray.length > 1">
                    <hb-checkbox
                      v-if="!isAuctionPaymentOrMovedOut(lease)"
                      :value="lease.pay"
                      @change="updateSelectedLeases(i, 'pay', !leasesArray[i].pay)"
                    ></hb-checkbox>
                  </span>
                  <span v-else-if="lease.Unit" class="pr-1">Space</span>
                  <span>
                    {{ lease.Unit && lease.Unit.number }}
                    <span v-if="lease.PaymentMethods && lease.PaymentMethods.filter((pm) => pm.auto_charge).length">
                      <v-icon color="primary" dense class="ml-2 my-0 py-0"
                        >mdi-autorenew</v-icon
                      >
                    </span>
                    <span v-if="isAuctionPaymentOrMovedOut(lease)" class="auction-space">(This space has been auctioned)</span>
                  </span>
                </div>
              </template>
              <template v-slot:actions>
                <span class="pr-6 hb-font-header-3-medium">
                  <span class="pr-4">Total Due:</span>{{ lease | totalLeaseDue | formatMoney }}
                </span>
              </template>
              <template v-slot:content>
                <v-row no-gutters style="1px solid #dfe3e8; margin-bottom: 0">
                  <v-col cols="12" sm="12" class="invoice_display">
                    <v-row
                      v-if="
                        !lease.OpenInvoices ||
                        (!lease.OpenInvoices.length && !lease.AdvanceInvoices.length)
                      "
                      class="invoice-row mb-4"
                    >
                      <v-col class="pt-12" style="height: 120px; text-align: center">
                        No open invoices found
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="invoice in lease.OpenInvoices"
                      :key="'closedLeaseInvoice_' + invoice.id"
                      no-gutters
                      class="invoice-row mb-4"
                    >
                      <v-col>
                        <!-- invoice lines  -->
                        <v-row v-if="invoice.number" no-gutters class="invoice-line-row">
                          <v-col>
                            <span class="caption">Invoice #{{ invoice.number }}</span>
                          </v-col>
                        </v-row>

                        <!-- rent invoice line  -->
                        <v-row
                          class="invoice-line-row"
                          v-for="line in invoice.InvoiceLines"
                          :key="'closedLeaseInvoiceRentLine_' + line.id"
                          no-gutters
                        >
                          <template v-if="line.Product.default_type == 'rent'">
                            <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                              <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                            </v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                          </template>
                        </v-row>

                        <!-- other invoice lines  -->
                        <v-row
                          class="invoice-line-row"
                          v-for="line in invoice.InvoiceLines"
                          :key="'closedLeaseInvoiceLine_' + line.id"
                          no-gutters
                        >
                          <template v-if="line.Product.default_type != 'rent'">
                            <v-col style="flex: 1 0 50%"
                              >{{ line.Product.name }}
                              <span
                                class="caption font-regular"
                                v-show="
                                  line.Service &&
                                  line.Service.recurring
                                "
                                >({{ line.start_date | formatSmallDate }} -
                                {{ line.end_date | formatSmallDate }})</span
                              >
                            </v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">{{
                              (line.cost * line.qty) | formatMoney
                            }}</v-col>
                          </template>
                        </v-row>

                        <!-- subtotal  -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                        >
                          <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            ><strong>{{ invoice.sub_total | formatMoney }}</strong></v-col
                          >
                        </v-row>

                        <!-- discounts  -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                          v-if="invoice.discounts"
                        >
                          <v-col style="flex: 1 0 50%">Discounts</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            >({{ invoice.discounts | formatMoney }})</v-col
                          >
                        </v-row>

                        <!-- tax  -->
                        <v-row class="invoice-line-row" no-gutters>
                          <v-col style="flex: 1 0 50%">Tax</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right">{{
                            invoice.total_tax | formatMoney
                          }}</v-col>
                        </v-row>

                        <!-- previous payments  -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                          v-if="invoice.total_payments"
                        >
                          <v-col style="flex: 1 0 50%">Previous Payments</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            >({{ invoice.total_payments | formatMoney }})</v-col
                          >
                        </v-row>

                        <!-- balance  -->
                        <v-row class="invoice-line-row" no-gutters>
                          <v-col style="flex: 1 0 50%" ><strong>Total</strong></v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            ><strong >{{
                              invoice.balance | formatMoney
                            }}</strong></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- advance invoices  -->
                    <v-row
                      v-for="(invoice, i) in lease.AdvanceInvoices"
                      :key="'closedLeaseAdvanceInvoice_' + i"
                      no-gutters
                      class="invoice-row mb-4"
                    >
                      <v-col>
                        <v-row
                          class="invoice-line-row"
                          v-for="line in invoice.InvoiceLines"
                          :key="'closedLeaseAdvanceInvoiceRentLine_' + line.id"
                          no-gutters
                        >
                          <v-col style="flex: 1 0 50%"
                            >{{ line.Product.name }}
                            <span
                              class="caption font-regular"
                              v-show="
                                line.Service &&
                                line.Service.recurring
                              "
                              >({{ line.start_date | formatSmallDate }} -
                              {{ line.end_date | formatSmallDate }})</span
                            >
                          </v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right">{{
                            (line.cost * line.qty) | formatMoney
                          }}</v-col>
                          <template v-if="line.Product.default_type == 'rent'">
                            <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                              <span class="caption font-regular" v-show="line.Service && line.Service.recurring">({{ line.start_date | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                            </v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">{{(line.cost * line.qty) | formatMoney}}</v-col>
                          </template>
                        </v-row>

                        <!-- other invoice lines  -->
                        <v-row
                          class="invoice-line-row"
                          v-for="line in invoice.InvoiceLines"
                          :key="'closedLeaseAdvanceInvoiceLine_' + line.id"
                          no-gutters
                        >
                          <template v-if="line.Product.default_type != 'rent'">
                            <v-col style="flex: 1 0 50%"
                              >{{ line.Product.name }}
                              <span
                                class="caption font-regular"
                                v-show="
                                  line.Service &&
                                  line.Service.recurring
                                "
                                >({{ line.start_date | formatSmallDate }} -
                                {{ line.end_date | formatSmallDate }})</span
                              >
                            </v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">{{
                              (line.cost * line.qty) | formatMoney
                            }}</v-col>
                          </template>
                        </v-row>

                        <!-- subtotal -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                        >
                          <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            ><strong>{{ invoice.sub_total | formatMoney }}</strong></v-col
                          >
                        </v-row>

                        <!-- discounts -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                          v-if="invoice.discounts"
                        >
                          <v-col style="flex: 1 0 50%">Discounts</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            >({{ invoice.discounts | formatMoney }})</v-col
                          >
                        </v-row>

                        <!-- tax -->
                        <v-row class="invoice-line-row" no-gutters>
                          <v-col style="flex: 1 0 50%">Tax</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right">{{
                            invoice.total_tax | formatMoney
                          }}</v-col>
                        </v-row>

                        <!-- previous payments -->
                        <v-row
                          class="invoice-line-row"
                          no-gutters
                          v-if="invoice.total_payments"
                        >
                          <v-col style="flex: 1 0 50%">Previous Payments</v-col>
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            >({{ invoice.total_payments | formatMoney }})</v-col
                          >
                        </v-row>

                        <!-- invoice balance -->
                        <v-row class="invoice-line-row" no-gutters>
                          <v-col style="flex: 1 0 50%"
                            ><strong>Total</strong></v-col
                          >
                          <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                          <v-col style="flex: 0 1 0%" class="align-right"
                            ><strong>{{
                              invoice.balance | formatMoney
                            }}</strong></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="invoice-line-row total pa-0 px-5">
                      <v-col 
                        align-self="center"
                        class="pl-0" 
                        cols="10"
                      >
                        Total Balance Due:
                      </v-col>
                      <v-col
                        align-self="center"
                        class="align-right font-weight-medium"
                        cols="2"
                      >
                        {{ lease | totalLeaseDue | formatMoney }}</v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </hb-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import api from '../../../assets/api';

export default {
  name: "SpaceInformation",
  mixins: [ notificationMixin ],
  props: ["paymentSource"],

  data: () => ({
    openMainPanel: 1,
    currentPanel: [0],
    closedPanel: [],
    show_edit_payment_cycle: null,
    payment_cycle_options: [], 
    payment_cycle_form: {
      lease: null, 
      lease_index: null,
      payment_cycle: {}
    },
    notification: false,
    closedLeaseCount: 0
  }),
  
  async created() {
    if(this.paymentSource == 'MOVE_OUT' && this.paymentData.totalDue > 0) {
      this.showMessageNotification({ description: 'The following invoices are past due. Please settle the balance before allowing the move-out.' })
    }
    this.startLoading(this.$options.name);
    this.preSelectLease();
    this.closedLeaseCount = this.leasesArray.filter(lease => !lease.lease_is_active && lease.balance > 0).length;
    this.stopLoading(this.$options.name);
  },
  filters: {
    totalLeaseDue(lease) {
      let lease_total = lease.OpenInvoices && lease.OpenInvoices.reduce((a, b) => a + b.balance, 0) || 0;
      let new_charges = lease.AdvanceInvoices && lease.AdvanceInvoices.reduce((a, b) => a + b.balance, 0) || 0;
      return Math.round((lease_total + new_charges) * 1e2) / 1e2;
    },
  },

  computed: {
    ...mapGetters({
      ivrPaymentSessionStarted:'charmCallStore/ivrPaymentSessionStarted',
      getMinBilledMonths: 'paymentsStore/getMinBilledMonths',
      shouldShowAdditionalMonths: 'paymentsStore/shouldShowAdditionalMonths',
      paymentConfig: 'paymentsStore/getPaymentSourceConfig',
      template:  'onBoardingStore/template',
      configuration_unit: "onBoardingStore/configuration_unit",
      activeDrawerComponent: 'navigationStore/activeDrawerComponent',
      leasesArray: 'paymentsStore/getLeases',
      properties: 'propertiesStore/properties',
      preSelectedLeaseId: 'paymentsStore/getPreSelectedLeaseId',
      paymentData: 'paymentsStore/getPaymentInformation',
      invoicesLoading: 'paymentsStore/getInvoicesLoading',
      connections: 'paymentsStore/getPropertyConnections',
    }),

    noConnections(){
      return !(this.connections && this.connections.length);
    }
  },
    mounted(){
    setTimeout(() => {
      this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "payment-button-clicked"})
    }, 2000);
  },
  methods: {
    ...mapMutations({
      setPaymentLeaseProperty: 'paymentsStore/setLeaseProperty',
      setLeaseBilledMonths: 'paymentsStore/setLeaseBilledMonths',
      setMinBilledMonths: 'onBoardingStore/setMinBilledMonths',
      setLeasePaymentCycle: 'paymentsStore/setLeasePaymentCycle',
      setPreSelectedLeaseId: 'paymentsStore/setPreSelectedLeaseId',
      setLeaseUnitNumber: 'paymentsStore/setLeaseUnitNumber',
    }),
    ...mapActions({
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
    }),

    additionalMonthsValue(lease){
      if(!lease.payment_cycle || lease.payment_cycle.toLowerCase() === 'monthly' ){
        return lease.billed_months;
      }else{
        let dFactor = lease.payment_cycle.toLowerCase() === 'quarterly' ? 3 : 12;
        let factorMonths = lease.billed_months/dFactor;
        if(this.paymentSource == 'MOVE_IN' || this.paymentSource == 'TRANSFERS'){
          return (Math.floor(factorMonths) * dFactor); 
        }else{
          return (Math.ceil(factorMonths) * dFactor);
        }
      }
      
    },

    additionalMonths(lease) {
      let months = [];
      let payment_cycle = lease.PaymentCycleOptions?.find(pc => pc.label.toLowerCase() === lease.payment_cycle?.toLowerCase())

      for (let i = this.getMinBilledMonths; i <= 12; i++) {
          if(i > 0) {

            if(!payment_cycle || !payment_cycle.period || i % payment_cycle.period === 0){
              months.push({
                text: i + " Month" + (i > 1 ? "s" : ""),
                value: i,
              });
            }
        }
      }
      return months;
    },
     editPaymentCycle(lease, index){
      let monthly = {id: null, period: 0, label:  "Monthly" }
      this.payment_cycle_form = {
        lease: lease,
        lease_index: index,
        payment_cycle:  lease.PaymentCycleOptions.find(po => po.label === lease.payment_cycle)  || monthly
      };
      
      this.payment_cycle_options = [].concat(monthly, lease.PaymentCycleOptions);
      this.show_edit_payment_cycle = true;
    }, 



    changeLeaseBilledMonths(value, lease, i) {
      if(value >= 0){
        this.setLeaseBilledMonths({
          leaseIndex: i,
          billedMonths: value || 0,
        });
      
        this.$emit('calculateFuturePayments', lease, i, true);
      }  
    },
    changeLeasePaymentCycle() {
      let payment_cycle = this.payment_cycle_form.payment_cycle, billed_months = 0;
      const lease = this.leasesArray[this.payment_cycle_form.lease_index];

      if (lease?.has_open_invoice) {
        if (lease?.oldOpenInvoices?.length === 0) {
          this.setLeaseProperty({
            leaseIndex: this.payment_cycle_form.lease_index,
            property: 'oldOpenInvoices',
            propertyValue: lease.OpenInvoices || []
          });
        }
        if (lease?.old_payment_cycle === (payment_cycle.label.toLowerCase() !== 'monthly' ? payment_cycle.label : null)) {
          billed_months = 0;
          this.setLeaseProperty({
            leaseIndex: this.payment_cycle_form.lease_index,
            property: 'OpenInvoices',
            propertyValue: lease?.oldOpenInvoices || []
          });
          this.setLeaseProperty({
            leaseIndex: this.payment_cycle_form.lease_index,
            property: 'use_old_invoices',
            propertyValue: true
          });
        } else {
          billed_months = payment_cycle.label.toLowerCase() !== 'monthly' ? lease.PaymentCycleOptions.find(po => po.label.toLowerCase() === payment_cycle.label.toLowerCase()).period : 1;
        }
      }
      
      this.setLeaseProperty({
        leaseIndex: this.payment_cycle_form.lease_index,
        property: 'payment_cycle',
        propertyValue: payment_cycle.label.toLowerCase() !== 'monthly' ? payment_cycle.label : null
      })
  
      this.show_edit_payment_cycle = false;
      this.setMinBilledMonths(0);
      this.changeLeaseBilledMonths(billed_months, this.leasesArray[this.payment_cycle_form.lease_index], this.payment_cycle_form.lease_index);
    },
   
  
    disableAutopay(l) { 
      let selected_unit = l.unit_id ? l.unit_id : this.configuration_unit.id;
      if(this.template && this.template[selected_unit]) {
        let unit_template = this.template[selected_unit];
        if(unit_template.auto_pay){
          return true;
        }
      }
      return this.paymentData.totalNewPaymentDue <= 0;
    },

    setLeaseProperty(payload) {
      this.setPaymentLeaseProperty(payload);
      if(this.activeDrawerComponent.type === 'showMakePayment'){
        this.setActiveDrawerComponent({ ...this.activeDrawerComponent, leases: this.leasesArray});
      }
    },
    isAuctionPaymentOrMovedOut(lease) {
      return lease.auction_status === 'move_out';
    },
    unitAddress(property_id){
      let property = this.properties.find(x=>x.id === property_id);
      if(property){
        return `(${property.number} - ${property.Address.city})`;
      } else {
        return '';
      }
    },
    preSelectLease() {
      if(this.preSelectedLeaseId) {
        // setting lease and it's data in store and then setting the panel data here...
        const closedLeases = this.leasesArray.filter(lease => !lease.lease_is_active && lease.balance > 0)
        const closedLeaseIndex = closedLeases.findIndex(record => record.id === this.preSelectedLeaseId)
  
        this.currentPanel = [],
        this.openMainPanel = 0;
        this.closedPanel = closedLeaseIndex;

      }
    },

    updateSelectedLeases(index, propertyName, value){
      this.setLeaseProperty({leaseIndex: index, property: propertyName, propertyValue: value});
      this.setLeaseUnitNumber({leaseIndex: index, propertyValue: value});
      this.$emit('updateSelectedLeasesInvoices');
    }
  }
};

</script>

<style scoped>
.title {
  font-size: 20px !important;
}

.navigation-panel-container {
  background: #f9fafb;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.panel-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.user-info,
.nav-drawer-header {
  background: white;
  flex: 0 1;
}

.info-panel {
  flex: 1 0;
  padding: 30px 50px;
  background-color: #f9fafb;
  border-left: 1px solid #e1e6ea;
}
.walkthrough-body {
  flex: 1;
}
.section-header {
  background: white;
}

.user-info {
  padding-left: 50px;
  padding-right: 5px;
  background-color: white;
}

.v-expansion-panel-content .form-label {
  background: #f4f6f8;
  padding: 20px;
}

.v-expansion-panel-content .form-label-active {
  background: #effafa;
  padding: 20px;
}

.v-expansion-panel-content .form-label-active h3 {
  color: #00848e;
}

.invoice_display {
  padding: 10px 0px;
  font-size: 14px;
}

.invoice-line-row {
  margin-bottom: 0;
  padding: 0px 20px;
}
.invoice-line-row .col {
  padding: 0;
}

.invoice-total-row > .col {
  padding: 10px;
}

.invoice-row .caption {
  color: #637381;
}

.invoice-row > .col {
  padding-bottom: 0;
}

.invoice-line-row.total {
  border-top: 1px solid #dfe3e8;
  margin-top: 20px;
}
.invoice-line-row.total > .col {
  padding-top: 15px;
}
.payment-data {
  background: white;
  border: 1px solid #dfe3e8;
  margin-bottom: 10px;
  border-radius: 0;
}

.payment-data-header .v-input--checkbox,
.form-label .v-input--checkbox {
  margin-top: 0;
  padding-top: 0;
}

.payment-data-header {
  border-bottom: 1px solid #dfe3e8;
  padding: 15px 20px;
  margin-bottom: 0;
}
.auto-debit-placeholder {
  border: 2px dashed #c4cdd5;
  border-radius: 4px;
  padding: 15px;
}
.new-tenant-form-data{
  border: 1px solid #DFE3E8;
}
.auction-space{
  color: #FB4C4C;
  font-style: italic;
}

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
  border: none !important;
}

.hb-closed-lease-background {
  background-color: transparent !important;
}

.hb-closed-lease-dropdown-header {
  min-height: 40px !important;
}
.hb-closed-lease-panel{
  border: 0 !important;
}
</style>
